import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: ${props => (props.isReport ? "0" : "40px 20px 20px 20px")};
  box-sizing: border-box;
  word-break: break-word;
  min-height: 100%;
  border-radius: 10px;
  background: #f2f6fc;
  & img {
    width: 100%;
  }
`;

export const AddContentBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  align-self: center;
  justify-self: center;
`;

export const ActionButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between
  margin-top: 20px;
`;

export const ActionButton = styled.button`
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-right: 15px;
  background-color: rgb(27, 33, 51, 0.66);
  min-width: 180px;
  padding: 10px 27px;
  font-size: 20px;
  font-weight: bold;
  color: #e4ebf7;
  line-height: 20px;
`;

export const TypesWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const TypeBtn = styled.div`
  width: 32%;
  box-sizing: border-box;
  flex-shrink: 0;
  padding: 12px 24px;
  background: rgb(27, 33, 51, 0.66);
  color: #ffffff;
  margin: 5px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.custom-file-upload {
    padding: 0;

    & > label {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      cursor: pointer;

      & > input {
        display: none;
      }
    }
  }
`;

export const InputURLWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const InputURLTitle = styled.span`
  font-size: 20px;
`;
export const InputURL = styled.input`
    margin-top: 20px;
    font-size: 16px;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #e1e3e6;
`;
export const InputURLSubmitBtnWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
export const InputURLSubmitBtn = styled.button`
  height: 40px;
  background: linear-gradient(90deg, #00c999, #00e67d);
  border: 1px solid transparent;
  transition: background 0.5s;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  line-height: 12px;
  border-radius: 16px;
  cursor: pointer;
  outline: none;
  margin-top: 15px;
  font-family: Roboto, sans-serif;
  width: 160px;
  align-self: flex-end;
  &:hover {
    background: linear-gradient(90deg, #00e67d, #33ef9d);
  }
`;
export const MarkersWrapper = styled.div`
  text-align: center;
`;
export const AddMarkerButton = styled.button`
  margin-bottom: 20px;
  height: 40px;
  background: linear-gradient(90deg, #00c999, #00e67d);
  border: 1px solid transparent;
  transition: background 0.5s;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  line-height: 12px;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  font-family: Roboto, sans-serif;
  width: 160px;
  align-self: flex-end;
  &:hover {
    background: linear-gradient(90deg, #00e67d, #33ef9d);
  }
`;
