import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";

import { actions as usersActions } from "redux/users";

import Modal from "components/Modal";

import * as S from "./styled";

const EditUserModal = ({ userIdToEdit, handleCloseModal, showModal }) => {
  const dispatch = useDispatch();
  const { usersList } = useSelector(({ users }) => ({
    usersList: users.usersList
  }));
  let userToEdit = {};

  if (userIdToEdit) {
    userToEdit = usersList.find(user => user._id === userIdToEdit);
  }

  const handleValidate = values => {
    let errors = {};
    const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { firstName, lastName, email, password, confirmPassword } = values;

    if (!firstName) {
      errors.firstName = "First name is required";
    }

    if (!lastName) {
      errors.lastName = "Last name is required";
    }

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!emailRegEx.test(email)) {
      errors.email = "Invalid email address";
    }

    if (password && password.length < 6) {
      errors.password = "Password should contain at least 6 characters";
    }

    if (password && !confirmPassword) {
      errors.confirmPassword = "Confirmation is required";
    } else if (confirmPassword !== password) {
      errors.confirmPassword = "Passwords do not match";
    }

    return errors;
  };

  const handleSubmit = values => {
    dispatch(
      usersActions.editUserRequest({
        values: { ...values, _id: userToEdit._id },
        cb: handleCloseModal
      })
    );
  };

  return (
    <Modal isOpen={showModal} onRequestClose={handleCloseModal}>
      <S.ModalTitle>
        <span>Edit User</span>
        <S.CloseModalBtn onClick={handleCloseModal}>x</S.CloseModalBtn>
      </S.ModalTitle>
      <Formik
        onSubmit={handleSubmit}
        validate={handleValidate}
        initialValues={{
          firstName: userToEdit.firstName || "",
          lastName: userToEdit.lastName || "",
          email: userToEdit.email || "",
          role: userToEdit.role || "",
          password: "",
          confirmPassword: ""
        }}
      >
        {props => (
          <S.Form>
            <S.FieldTitle>First name</S.FieldTitle>
            <S.Field
              name="firstName"
              type="string"
              placeholder="Enter First Name"
            />
            <S.ErrMessage component="div" name="firstName" />
            <S.FieldTitle>Last name</S.FieldTitle>
            <S.Field
              name="lastName"
              type="string"
              placeholder="Enter Last Name"
            />
            <S.ErrMessage component="div" name="lastName" />
            <S.FieldTitle>Email</S.FieldTitle>
            <S.Field name="email" type="string" placeholder="Enter the Email" />
            <S.ErrMessage component="div" name="email" />
            <S.FieldTitle>Role</S.FieldTitle>
            <S.Field
              name="role"
              as="select"
              value={props.values.role}
              onChange={e => props.setFieldValue("role", e.target.value, true)}
            >
              <option value="user">User</option>
              <option value="expert">Expert</option>
              <option value="admin">Admin</option>
            </S.Field>
            <S.FieldTitle>Password</S.FieldTitle>
            <S.Field
              name="password"
              type="password"
              placeholder="At least 6 characters long"
            />
            <S.ErrMessage component="div" name="password" />
            <S.FieldTitle>Confirm your password</S.FieldTitle>
            <S.Field
              name="confirmPassword"
              type="password"
              placeholder="Enter password again"
            />
            <S.ErrMessage component="div" name="confirmPassword" />
            <S.SubmitButton type="submit">SUBMIT</S.SubmitButton>
          </S.Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditUserModal;
