import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";

import LoadingSpinner from "components/LoadingSpinner";

import { actions as authActions } from "redux/auth";
import { actions as usersActions } from "redux/users";

import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import GoogleAuth from "./components/GoogleAuth";

import ProjectPage from "../ProjectPage";
import ProjectListPage from "../ProjectListPage";
import AccountListPage from "../AccountListPage";
import OverviewPage from "modules/OverviewPage";
import ProfilePage from "modules/ProfilePage";

import * as S from "./styled";
import GridManager from "modules/ProjectPage/components/GridManager";

function App() {
  const [isOpen, setIsOpen] = useState(true);
  const dispatch = useDispatch();
  const { isAuthenticated, profile } = useSelector(({ auth }) => ({
    isAuthenticated: auth.isAuthenticated,
    profile: auth.profile
  }));

  useEffect(() => {
    dispatch(authActions.getProfileRequest());
    dispatch(usersActions.getUsersRequest());
  }, [dispatch]);
  const handleBurgerClick = () => {
    setIsOpen(!isOpen);
  };

  const getRoutesByRole = () => {
    let routes;
    switch (profile.role) {
      case "admin":
        routes = (
          <Switch>
            <Route exact path="/app/projects" component={ProjectListPage} />
            <Route
              exact
              path="/app/projects/:projectId"
              component={ProjectPage}
            />
            <Route exact path="/app/dashboard" component={OverviewPage} />
            <Route exact path="/app/accounts" component={AccountListPage} />
            <Route exact path="/app/profile" component={ProfilePage} />
            <Route exact path="/app/googleAuth" component={GoogleAuth} />
            <Route
              path="/"
              component={() => <Redirect to="/app/dashboard" />}
            />
          </Switch>
        );
        break;
      case "expert":
        routes = (
          <Switch>
            <Route exact path="/app/projects" component={ProjectListPage} />
            <Route
              exact
              path="/app/projects/:projectId"
              component={ProjectPage}
            />
            <Route exact path="/app/dashboard" component={OverviewPage} />
            <Route exact path="/app/profile" component={ProfilePage} />
            <Route exact path="/app/googleAuth" component={GoogleAuth} />
            <Route
              path="/"
              component={() => <Redirect to="/app/dashboard" />}
            />
          </Switch>
        );
        break;
      default:
        routes = (
          <Switch>
            <Route exact path="/app/projects" component={ProjectListPage} />
            <Route
              exact
              path="/app/projects/:projectId"
              component={ProjectPage}
            />
            <Route exact path="/app/profile" component={ProfilePage} />
            <Route exact path="/app/googleAuth" component={GoogleAuth} />
            <Route path="/" component={() => <Redirect to="/app/projects" />} />
          </Switch>
        );
    }
    return routes;
  };

  if (!isAuthenticated) {
    return <LoadingSpinner />;
  }

  return (
    <Switch>
      <Route exact path="/app/projectReport/:projectId">
        <GridManager isReport />
      </Route>
      <Route path="/app">
        <S.MainWrapper isOpen={isOpen}>
          <Sidebar onBurgerClick={handleBurgerClick} isOpen={isOpen} />
          <S.MainContent>
            <Header />
            <S.ContentWrapper>{getRoutesByRole()}</S.ContentWrapper>
          </S.MainContent>
        </S.MainWrapper>
      </Route>
    </Switch>
  );
}

export default App;
