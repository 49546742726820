import React from "react";
import { useSelector } from "react-redux";
import Marker from "./components/Marker";

import * as S from "./styled";

function Markers({ content, onChangeMarker, isReport }) {
  const {
    profile: { role }
  } = useSelector(({ auth }) => ({
    profile: auth.profile
  }));

  const renderMarkers = () =>
    content.markers.map((marker, index) => (
      <Marker
        key={marker.id}
        marker={marker}
        onChangeMarker={onChangeMarker}
        index={index}
        role={role}
      />
    ));
  const renderMarkersTable = () => (
    <>
      <S.MarksDescrTitle>Markers Description</S.MarksDescrTitle>
      <S.MarksList>
        {content.markers.map((marker, index) => (
          <li>{`${index + 1}. ${marker.comment || "-"}`}</li>
        ))}
      </S.MarksList>
    </>
  );

  return (
    <>
      <S.Wrapper>
        <img src={content.screenshotURL} />
        {renderMarkers()}
      </S.Wrapper>
      {isReport && renderMarkersTable()}
    </>
  );
}

export default Markers;
