import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";

import LoadingSpinner from "components/LoadingSpinner";

import { actions as usersActions } from "redux/users";

import avatarThumb from "assets/images/avatar.png";

import * as S from "./styled";

function ProfilePage() {
  const dispatch = useDispatch();
  const { profile } = useSelector(({ auth }) => ({
    profile: auth.profile
  }));
  const [avatar, setAvatar] = useState(profile.avatarURL || avatarThumb);
  const [avatarFile, setAvatarFile] = useState(null);

  const handleValidate = values => {
    let errors = {};
    const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { firstName, lastName, email, password, confirmPassword } = values;

    if (!firstName) {
      errors.firstName = "First name is required";
    }

    if (!lastName) {
      errors.lastName = "Last name is required";
    }

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!emailRegEx.test(email)) {
      errors.email = "Invalid email address";
    }

    if (password && password.length < 6) {
      errors.password = "Password should contain at least 6 characters";
    }

    if (password && !confirmPassword) {
      errors.confirmPassword = "Confirmation is required";
    } else if (confirmPassword !== password) {
      errors.confirmPassword = "Passwords do not match";
    }

    return errors;
  };

  const handleChangeAvatar = e => {
    var reader = new FileReader();

    reader.onload = function() {
      setAvatar(reader.result);
    };

    setAvatarFile(e.target.files[0]);
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleSubmit = values => {
    dispatch(
      usersActions.editUserRequest({
        values: { ...values, _id: profile._id, role: profile.role },
        cb: () => {}
      })
    );
    if (avatarFile) {
      dispatch(
        usersActions.saveUserAvatarRequest({
          avatarFile,
          userId: profile._id
        })
      );
    }
  };

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>Edit Profile</S.Title>
      </S.Header>
      <Formik
        onSubmit={handleSubmit}
        validate={handleValidate}
        initialValues={{
          firstName: profile.firstName || "",
          lastName: profile.lastName || "",
          email: profile.email || "",
          password: "",
          confirmPassword: ""
        }}
      >
        {() => (
          <S.Form>
            <S.FieldTitle>First name</S.FieldTitle>
            <S.Field
              name="firstName"
              type="string"
              placeholder="Enter First Name"
            />
            <S.ErrMessage component="div" name="firstName" />
            <S.FieldTitle>Last name</S.FieldTitle>
            <S.Field
              name="lastName"
              type="string"
              placeholder="Enter Last Name"
            />
            <S.ErrMessage component="div" name="lastName" />
            <S.FieldTitle>Email</S.FieldTitle>
            <S.Field name="email" type="string" placeholder="Enter the Email" />
            <S.ErrMessage component="div" name="email" />
            <S.FieldTitle>Password</S.FieldTitle>
            <S.Field
              name="password"
              type="password"
              placeholder="At least 6 characters long"
            />
            <S.ErrMessage component="div" name="password" />
            <S.FieldTitle>Confirm your password</S.FieldTitle>
            <S.Field
              name="confirmPassword"
              type="password"
              placeholder="Enter password again"
            />
            <S.ErrMessage component="div" name="confirmPassword" />
            <S.AvatarUploadWrapper>
              <input
                type="file"
                name="projectLogo"
                id="project-logo-upload"
                onChange={handleChangeAvatar}
              />
              <S.Avatar>
                <img src={avatar}></img>
              </S.Avatar>
            </S.AvatarUploadWrapper>
            <S.SubmitButton type="submit">SUBMIT</S.SubmitButton>
          </S.Form>
        )}
      </Formik>
    </S.Wrapper>
  );
}

export default ProfilePage;
