import React from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import RegistrationPage from "modules/RegistrationPage";
import LoginPage from "modules/LoginPage";
import App from "modules/App";

function AppRouter() {
  const { token } = useSelector(({ auth }) => ({ token: auth.token }));

  if (token) {
    return (
      <Router>
        <Switch>
          <Route path="/app">
            <App />
          </Route>
          <Route path="/">
            <Redirect to="/app" />
          </Route>
        </Switch>
      </Router>
    );
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/registration" component={RegistrationPage} />
        <Route exact path="/login" component={LoginPage} />
        <Route path="/" component={() => <Redirect to="/login" />} />
      </Switch>
    </Router>
  );
}

export default AppRouter;
