import React, { useState } from "react";

import * as S from "./styled";
import Confirm from "components/Confirm";

function Grid({ onChangeSize, initialSize, handleCopyGrid, handleDeleteGrid }) {
  const [isSizesOpen, setIsSizesOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [selectedSize, setSelectedSize] = useState(initialSize);
  const [changeSizeParams, setChangeSizeParams] = useState({});

  const handleChoose = (size, style, quantity) => () => {
    setIsConfirmOpen(true);
    setChangeSizeParams({ size, style, quantity });
  };

  const handleOk = () => {
    const { size, style, quantity } = changeSizeParams;
    setSelectedSize(size);
    setIsSizesOpen(false);
    setIsConfirmOpen(false);
    onChangeSize(size, style, quantity);
  };

  const handleNo = () => {
    setIsConfirmOpen(false);
    setIsSizesOpen(false);
  };

  return (
    <S.Wrapper>
      <S.GridActionsWrapper>
        <S.GridActionButton onClick={() => setIsSizesOpen(true)}>
          {selectedSize}
        </S.GridActionButton>
        <S.GridActionButton onClick={handleCopyGrid}>copy</S.GridActionButton>
        <S.GridActionButton onClick={handleDeleteGrid}>del</S.GridActionButton>
      </S.GridActionsWrapper>
      {isSizesOpen && (
        <S.SizeVariants>
          <S.SizeButton
            onClick={handleChoose(
              "1/6",
              { gridTemplateColumns: "repeat(6, 1fr)" },
              6
            )}
          >
            1/6
          </S.SizeButton>
          <S.SizeButton
            onClick={handleChoose(
              "1/5",
              { gridTemplateColumns: "repeat(5, 1fr)" },
              5
            )}
          >
            1/5
          </S.SizeButton>
          <S.SizeButton
            onClick={handleChoose(
              "1/4",
              { gridTemplateColumns: "repeat(4, 1fr)" },
              4
            )}
          >
            1/4
          </S.SizeButton>
          <S.SizeButton
            onClick={handleChoose(
              "1/3",
              { gridTemplateColumns: "repeat(3, 1fr)" },
              3
            )}
          >
            1/3
          </S.SizeButton>
          <S.SizeButton
            onClick={handleChoose(
              "2/5",
              { gridTemplateColumns: "2fr 2fr 1fr" },
              3
            )}
          >
            2/5
          </S.SizeButton>
          <S.SizeButton
            onClick={handleChoose("1/2", { gridTemplateColumns: "1fr 1fr" }, 2)}
          >
            1/2
          </S.SizeButton>
          <S.SizeButton
            onClick={handleChoose("3/5", { gridTemplateColumns: "3fr 2fr" }, 2)}
          >
            3/5
          </S.SizeButton>
          <S.SizeButton
            onClick={handleChoose("2/3", { gridTemplateColumns: "2fr 1fr" }, 2)}
          >
            2/3
          </S.SizeButton>
          <S.SizeButton
            onClick={handleChoose("3/4", { gridTemplateColumns: "3fr 1fr" }, 2)}
          >
            3/4
          </S.SizeButton>
          <S.SizeButton
            onClick={handleChoose("4/5", { gridTemplateColumns: "4fr 1fr" }, 2)}
          >
            4/5
          </S.SizeButton>
          <S.SizeButton
            onClick={handleChoose("5/6", { gridTemplateColumns: "5fr 1fr" }, 2)}
          >
            5/6
          </S.SizeButton>
          <S.SizeButton
            onClick={handleChoose("1/1", { gridTemplateColumns: "1fr" }, 1)}
          >
            1/1
          </S.SizeButton>
        </S.SizeVariants>
      )}
      <Confirm
        isOpen={isConfirmOpen}
        mainTitle="Do you want to change grid size?"
        subTitle="All data in grid blocks will be lost"
        onRequestClose={handleNo}
        onNo={handleNo}
        onOk={handleOk}
      />
    </S.Wrapper>
  );
}

export default Grid;
