import { combineReducers } from "redux";

import auth from "./auth";
import users from "./users";
import projects from "./projects";

const rootReducer = combineReducers({
  auth,
  projects,
  users
});

export default rootReducer;
