import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import LoadingSpinner from "components/LoadingSpinner";

import { actions as projectsActions } from "redux/projects";

import ProjectsAssignment from "./components/ProjectsAssignment";

import * as S from "./styled";

function OverviewPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loadingStatusOverview,
    statusOverview,
    loadingUsers,
    profile: { role }
  } = useSelector(({ projects, users, auth }) => ({
    loadingStatusOverview: projects.loadingStatusOverview,
    statusOverview: projects.statusOverview,
    loadingUsers: users.loadingUsers,
    profile: auth.profile
  }));

  useEffect(() => {
    dispatch(projectsActions.getStatusOverviewRequest());
  }, [dispatch]);

  if (loadingStatusOverview || loadingUsers) {
    return (
      <S.SpinnerWrapper>
        <LoadingSpinner />
      </S.SpinnerWrapper>
    );
  }

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>Project Status Overview</S.Title>
      </S.Header>
      <S.StatusOverviewGrid>
        <S.StatusOverviewItem
          onClick={() => history.push("projects/?projectStatus=open")}
        >
          <S.StatusOverviewTitle>Open</S.StatusOverviewTitle>
          <S.StatusOverviewCount className="open">
            {statusOverview.open || "0"}
          </S.StatusOverviewCount>
        </S.StatusOverviewItem>
        <S.StatusOverviewItem
          onClick={() => history.push("projects/?projectStatus=checking")}
        >
          <S.StatusOverviewTitle>Checking</S.StatusOverviewTitle>
          <S.StatusOverviewCount className="checking">
            {statusOverview.checking || "0"}
          </S.StatusOverviewCount>
        </S.StatusOverviewItem>
        <S.StatusOverviewItem
          onClick={() => history.push("projects/?projectStatus=complete")}
        >
          <S.StatusOverviewTitle>Complete</S.StatusOverviewTitle>
          <S.StatusOverviewCount className="complete">
            {statusOverview.complete || "0"}
          </S.StatusOverviewCount>
        </S.StatusOverviewItem>
        <S.StatusOverviewItem
          onClick={() => history.push("projects/?projectStatus=inProcess")}
        >
          <S.StatusOverviewTitle>In Process</S.StatusOverviewTitle>
          <S.StatusOverviewCount className="inProcess">
            {statusOverview.inProcess || "0"}
          </S.StatusOverviewCount>
        </S.StatusOverviewItem>
      </S.StatusOverviewGrid>
      {role === "admin" && <ProjectsAssignment />}
    </S.Wrapper>
  );
}

export default OverviewPage;
