import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${props => (props.active ? "#0c1224" : "transparent")};
  border-left: ${props => (props.active ? "3px solid #f45b63" : "0")};
  padding-left: ${props => (props.active ? "21px" : "24px")};
`;

export const LinkInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuIcon = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: 16px;
  & img {
    height: 100%;
  }
`;

export const MenuTitle = styled.span`
  font-size: 16px;
  color: #e4ebf7;
  text-decoration: none;
  transition: 0.3s;
  overflow: hidden;
  white-space: nowrap;
  width: ${props => (props.isOpen ? "100%" : "0")};
`;

export const Badge = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e4ebf7;
  font-size: 20px;
  font-weight: 700;
  background: #f45b63;
  text-decoration: none;
`;
