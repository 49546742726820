import styled from "styled-components";

export const Wrapper = styled.div``;

export const Header = styled.div`
  display: flex;
  padding: 16px 28px;
  justify-content: space-between;
  cursor: pointer;
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: 400;
  color: #515974;
  width: ${props => (props.isOpen ? "100%" : "0")};
  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s;
`;

export const ExpandIcon = styled.div`
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  & img {
    transition: transform 0.4s;
    &.expanded {
      transform: rotate(180deg);
    }
  }
`;

export const Area = styled.div`
  transition: max-height 0.4s;
  overflow: hidden;
  max-height: 0px;
  &.expanded {
    max-height: 400px;
  }
`;
