import React from "react";

import LoginForm from "./components/LoginForm";

import * as S from "./styled";

function RegistrationPage({ history }) {
  return (
    <S.Container>
      <S.Title>Sign In</S.Title>
      <LoginForm history={history} />
    </S.Container>
  );
}

export default RegistrationPage;
