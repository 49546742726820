import styled from "styled-components";

export const Table = styled.table`
  width: calc(100% - 80px);
  margin: 30px 16px;
  border-collapse: collapse;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid rgba(167, 167, 167, 0.55);
`;

export const HeadCell = styled.th`
  text-align: left;
  font-size: 18px;
  line-height: 18px;
  padding: 12px 0;
  font-weight: 300;
  font-style: italic;
  color: #515974;
  &:first-child {
    padding-left: 20px;
  }
`;

export const EmailCell = styled.td`
  padding: 20px 0;
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  color: #515974;
  padding: 30px 14px;
`;

export const InfoCell = styled.td`
  font-size: 18px;
  line-height: 18px;
  font-weight: 300;
  font-style: italic;
  color: #515974;
`;

export const ActionButton = styled.button`
  border: none;
  outline: none;
  border-radius: 30px;
  background-color: #bababa;
  padding: 8px 40px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: #f2f6fc;
  margin-right: 20px;
  cursor: pointer;
  &.editBtn {
    &:hover {
      background: #2695b5;
    }
  }
  &.deleteBtn {
    &:hover {
      background: red;
    }
  }
`;
