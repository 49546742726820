import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 5000;
  & > div {
    width: 100%;
    height: 100%;
  }
`;
export const SearchArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-right: 25px;
  position: relative;
`;

export const SearchInput = styled.input`
  font-size: 18px;
  color: #515974;
  background: none;
  outline: none;
  border: none;
  width: 100%;
`;

export const ActionButton = styled.button`
  background: transparent;
  outline: none;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 20px;
  &:last-child {
    margin-right: 0px;
  }
  & img {
    height: 24px;
  }
`;

export const ResultArea = styled.div`
  position: absolute;
  top: calc(100% - 10px);
  box-shadow: 12px 12px 44px -6px rgba(0, 0, 0, 0.6);
  overflow-y: auto;
  background: white;
  width: 100%;
  height: fit-content;
  border-radius: 8px;
`;
export const ProjectItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #cccccc;
  cursor: pointer;
  &:hover {
    background: #eeeeee;
  }
`;
export const ProjectInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
export const InfoMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: #cccccc;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const ProjectName = styled.span`
  font-size: 20px;
  color: #515974;
  font-weight: 500;
`;
export const ProjectPage = styled.span`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  color: #515974;
`;
export const ProjectLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;

  & > img {
    width: 120%;
  }
`;
export const Link = styled(RouterLink)`
  text-decoration: none;
`;
