import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import LoadingSpinner from "components/LoadingSpinner";

import { actions as projectsActions } from "redux/projects";

import Table from "./components/Table";
import EditProjectModal from "./components/EditProjectModal";
import AddProjectModal from "./components/AddProjectModal";

import * as S from "./styled";

function ProjectPage() {
  const dispatch = useDispatch();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [projectIdToEdit, setProjectIdToEdit] = useState("");
  const { loadingProjects, loadingUsers, usersList } = useSelector(
    ({ projects, users }) => ({
      loadingProjects: projects.loadingProjects,
      loadingUsers: users.loadingUsers,
      usersList: users.usersList
    })
  );

  const experts = usersList.filter(user => user.role === "expert");

  const handleOpenEditModal = projectId => {
    setShowEditModal(true);
    setProjectIdToEdit(projectId);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleOpenAddModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  useEffect(() => {
    dispatch(projectsActions.getProjectsRequest());
  }, [dispatch]);

  if (loadingProjects || loadingUsers) {
    return (
      <S.SpinnerWrapper>
        <LoadingSpinner />
      </S.SpinnerWrapper>
    );
  }

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>Projects</S.Title>
        <S.AddButton onClick={handleOpenAddModal}>Create project</S.AddButton>
      </S.Header>
      <Table onOpenModal={handleOpenEditModal} experts={experts} />
      <EditProjectModal
        projectIdToEdit={projectIdToEdit}
        showModal={showEditModal}
        handleCloseModal={handleCloseEditModal}
      />
      <AddProjectModal
        showModal={showAddModal}
        handleCloseModal={handleCloseAddModal}
      />
    </S.Wrapper>
  );
}

export default ProjectPage;
