import styled from "styled-components";

export const SpinnerWrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const Wrapper = styled.div`
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.15);
  background-color: #f2f6fc;
`;

export const Header = styled.div`
  display: flex;
  border-bottom: 2px solid #e1e7f0;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 22px;
  line-height: 22px;
  font-weight: bold;
  color: #515974;
  display: block;
  padding: 12px 30px;
`;

export const StatusOverviewGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 15px 0;
  border-bottom: 2px solid #e1e7f0;
  margin-bottom: 20px;
`;
export const StatusOverviewItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;
export const StatusOverviewTitle = styled.span`
  color: #515974;
  font-size: 20px;
  font-weight: bold;
`;
export const StatusOverviewCount = styled.span`
  font-size: 20px;
  font-weight: bold;
  &.open {
    color: #2695b5;
  }
  &.checking {
    color: #f45b63;
  }
  &.complete {
    color: #5fb526;
  }
  &.inProcess {
    color: #ea9c14;
  }
`;
