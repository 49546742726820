import styled from "styled-components";

export const ActionButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between
  margin-top: 20px;
`;

export const ActionButton = styled.button`
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-right: 15px;
  background-color: rgba(27, 33, 51, 0.66);
  min-width: 180px;
  padding: 10px 27px;
  font-size: 20px;
  font-weight: bold;
  color: #e4ebf7;
  line-height: 20px;
`;

export const MetricsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Metrica = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100px;
  height: 40px;
  margin: 10px;
  align-items: center;
  border: 1px solid rgba(27, 33, 51, 0.66);
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  background: ${props =>
    props.isActive ? "rgba(27, 33, 51, 0.66)" : "transparent"};
  color: ${props => (props.isActive ? "#FFFFFF" : "rgba(27, 33, 51, 0.66)")};

  &:hover {
    background: ${props =>
      props.isActive ? "rgba(27, 33, 51, 0.88)" : "rgba(27, 33, 51, 0.33)"};
    color: #ffffff;
  }
`;
