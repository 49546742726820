import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useLocation, useHistory } from "react-router-dom";

import LoadingSpinner from "components/LoadingSpinner";

import { actions as projectsActions } from "redux/projects";

import * as S from "./styled";

function GoogleAuth() {
  const dispatch = useDispatch();
  let history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const code = query.get("code");
  const projectId = query.get("state");

  useEffect(() => {
    dispatch(
      projectsActions.addGoogleUserRequest({ projectId, code, history })
    );
  }, [dispatch]);

  if (!code || !projectId) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <S.Wrapper>
      <LoadingSpinner />
    </S.Wrapper>
  );
}

export default GoogleAuth;
