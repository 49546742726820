import styled from 'styled-components';
import { Form as FormikForm, Field as FormikField, ErrorMessage} from 'formik';

export const Form = styled(FormikForm)`
    padding: 15px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 450px;
    border: 1px solid #e6e8eb;
    border-radius: 12px;
    margin-top: 20px;
`;

export const Field = styled(FormikField)`
    height: 30px;
    border: 1px solid #e1e3e6;
    outline: none;
    font-size: 13px;
    background: transparent;
    color: #3e3e3e;  
    border-radius: 8px;
    padding: 0 12px;
    margin-bottom: 15px;
    &::placeholder {
        font-style: italic;
        color: #e1e3e;
    }
`;

export const FieldTitle = styled.span`
    font-size: 13px;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 7px;
    margin-top: 10px;
    color: #363840;  
`;

export const ErrMessage = styled(ErrorMessage)`
    font-size: 12px;
    line-height: 20px;
    margin-top: -10px;
    color: red;
`;

export const SubmitButton = styled.button`
    height: 35px;
    background: linear-gradient(90deg,#00c999,#00e67d);
    border: 1px solid transparent;
    transition: background .5s;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    line-height: 12px;
    border-radius: 16px;
    cursor: pointer;
    outline: none;
    margin-top: 15px;
    font-family: Roboto, sans-serif;
    width: 140px;
    align-self: flex-end;
    &:hover {
        background: linear-gradient(90deg,#00e67d,#33ef9d);
    }
`;