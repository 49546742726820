import React from "react";

import * as S from "./styled";

const LoadingSpinner = () => {
  return (
    <S.Loader>
      <S.Inner className="one"></S.Inner>
      <S.Inner className="two"></S.Inner>
      <S.Inner className="three"></S.Inner>
    </S.Loader>
  );
};

export default LoadingSpinner;
