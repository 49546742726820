import { call, put, takeLatest } from "redux-saga/effects";

import * as httpAuth from "http/auth";

import { actions as types } from "./index";

import { setToken } from "services/storage/auth";

function* onAuthenticate({ payload }) {
  const { values } = payload;

  try {
    const params = {
      ...values,
      email: values.email.trim()
    };
    const result = yield call(httpAuth.authenticateUser, params);
    const { token } = result.data;
    setToken(token);
    yield put(types.authenticationSuccess({ token }));
  } catch (error) {
    console.log(error);
    yield put(types.authenticationFailure());
  }
}

function* onGetProfile() {
  try {
    const result = yield call(httpAuth.getProfile);
    const profile = result.data;

    yield put(types.getProfileSuccess({ profile }));
  } catch (error) {
    console.log(error);
    yield put(types.getProfileFailure());
  }
}

const authSagas = [
  takeLatest(types.authenticationRequest, onAuthenticate),
  takeLatest(types.getProfileRequest, onGetProfile)
];

export default authSagas;
