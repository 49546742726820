import React, { useState } from "react";

import expandIcon from "assets/images/expandIconSidebar.png";

import * as S from "./styled";

function ExpandArea({ title, children, isOpen }) {
  const [expanded, setExpanded] = useState(true);

  return (
    <S.Wrapper>
      <S.Header onClick={() => setExpanded(!expanded)}>
        <S.Title isOpen={isOpen}>{title}</S.Title>
        <S.ExpandIcon>
          <img
            src={expandIcon}
            alt="Expand Icon"
            className={`${expanded && "expanded"}`}
          />
        </S.ExpandIcon>
      </S.Header>
      <S.Area className={`${expanded && "expanded"}`}>{children}</S.Area>
    </S.Wrapper>
  );
}

export default ExpandArea;
