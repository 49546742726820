import React from "react";
import ReactModal from "react-modal";

const style = {
  overlay: {
    background: "rgba(0,0,0,0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000
  },
  content: {
    maxWidth: "750px",
    minWidth: "320px",
    width: "100%",
    position: "static",
    height: "auto",
    background: "#f2f6fc",
    color: "#515974",
    borderRadius: "6px",
    border: "none"
  }
};

ReactModal.setAppElement("#root");

function Modal(props) {
  return (
    <ReactModal style={style} shouldCloseOnOverlayClick={true} {...props}>
      {props.children}
    </ReactModal>
  );
}

export default Modal;
