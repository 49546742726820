import styled from "styled-components";

export const GridWrapper = styled.div`
  display: grid;
  min-height: ${props => (props.isReport ? "auto" : "650px")};
  box-sizing: border-box;
  grid-column-gap: 16px;
  background-color: rgba(27, 33, 51, 0.33);
  padding: ${props => (props.isReport ? "8px 16px" : "16px")};
  width: 100%;
  position: relative;
`;
