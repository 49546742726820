import styled from "styled-components";

export const Sidebar = styled.div`
  background: #1b2133;
  transition: 0.3s;
  flex-shrink: 0;
  overflow: hidden;
  width: ${props => (props.isOpen ? "220px" : "74px")};
`;

export const Header = styled.div`
  height: 76px;
  padding: ${props => (props.isOpen ? "0 26px 0 26px" : "0 34px 0 22px")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s;
  box-sizing: border-box;
  width: 100%;
  > img {
    height: 44px;
  }
`;

export const BurgerMenu = styled.button`
  height: 26px;
  width: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  background: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
  > div {
    width: 22px;
    height: 2px;
    background: white;
    transition: 0.3s;
  }
  &.open {
    & > div:nth-child(1) {
      transform: translateY(12px) rotate(45deg);
    }
    & > div:nth-child(2) {
      opacity: 0;
    }
    & > div:nth-child(3) {
      transform: translateY(-12px) rotate(-45deg);
    }
  }
`;

export const UserInfo = styled.div`
  transition: 0.3s;
  padding: ${props => (props.isOpen ? "12px" : "12px 0px")};
  border-top: 1px solid #0c1224;
  border-bottom: 1px solid #0c1224;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const Avatar = styled.div`
  border-radius: 100%;
  overflow: hidden;
  flex-shrink: 0;
  transition: 0.3s;
  width: ${props => (props.isOpen ? "58px" : "58px")};
  height: ${props => (props.isOpen ? "58px" : "58px")};
  margin-left: ${props => (props.isOpen ? "8px" : "6px")};
  margin-right: 16px;
  background: #ffffff;
  & img {
    height: 100%;
    width: 100%;
  }
`;

export const TextInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.span`
  font-size: 20px;
  color: #e4ebf7;
  line-height: 24px;
  display: inline-block;
  margin-bottom: 5px;
`;

export const Role = styled.span`
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  color: #e4ebf7;
`;
