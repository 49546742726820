import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  z-index: 20;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(27, 33, 51, 0.66);
  padding: 10px;
  border-radius: 10px;
  border-top-left-radius: 0;
`;

export const GridActionsWrapper = styled.div`
  display: flex;
`;

export const GridActionButton = styled.button`
  width: 42px;
  height: 42px;
  border: none;
  margin: 1px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: #ffffff;
  cursor: pointer;
  color: #a8adb7;
`;

export const SizeVariants = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 265px;
`;

export const SizeButton = styled.button`
  width: 42px;
  height: 42px;
  border: none;
  margin: 1px;
  outline: none;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  cursor: pointer;
  color: #515974;
  &:hover {
    background-color: #54a6de;
    color: #FFFFFF;
  }
}`;
