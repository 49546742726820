import React from "react";

import * as S from "./styled";

function PageviewsGAReport({ content }) {
  const formatCell = cell => {
    console.log(typeof +cell);
    if (!isNaN(cell)) {
      return Number(cell).toFixed(2);
    }
    return cell;
  };
  const renderRows = () =>
    content.map(row => (
      <tr>
        {row.map(cell => (
          <td>{formatCell(cell)}</td>
        ))}
      </tr>
    ));

  return (
    <>
      <S.Title>
        <span>Pageviews GA Report</span>
      </S.Title>
      <S.Table>
        <tbody>
          <S.TableHead>
            <td>Page</td>
            <td>Page Views</td>
            <td>
              Unique <br />
              Page Views
            </td>
            <td>
              Avg. Time On <br />
              Page, sec
            </td>
            <td>Entrances</td>
            <td>Bounce Rate, %</td>
          </S.TableHead>
          {renderRows()}
        </tbody>
      </S.Table>
    </>
  );
}

export default PageviewsGAReport;
