import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

import WhiteArrowDown from "assets/images/whiteSelectIcon.png";

export const Table = styled.table`
  width: calc(100% - 80px);
  margin: 40px 25px;
  border-collapse: collapse;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid rgba(167, 167, 167, 0.55);
`;

export const HeadCell = styled.th`
  text-align: left;
  font-size: 16px;
  line-height: 16px;
  padding: 12px 0;
  font-weight: 300;
  font-style: italic;
  color: #515974;
  &:first-child {
    padding-left: 12px;
  }
`;

export const NameCell = styled.td`
  padding: 22px 0;
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  color: #515974;
  padding: 30px 12px;
`;

export const PageCell = styled.td`
  font-size: 18px;
  line-height: 18px;
  font-weight: 300;
  font-style: italic;
  color: #515974;
`;

export const CRI = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  padding: 7px 12px;
  color: #ffffff;
  border-radius: 5px;
  background-color: #495061;
  display: inline-block;
`;

export const DateCell = styled.td`
  font-size: 18px;
  color: #515974;
`;

export const EditBtn = styled.button`
  border: none;
  outline: none;
  border-radius: 30px;
  background-color: #bababa;
  padding: 10px 25px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  color: #f2f6fc;
  margin-right: 12px;
  cursor: pointer;
  &:hover {
    background: #2695b5;
  }
`;

export const StatusSelect = styled.select`
  appearance: none;
  border: none;
  outline: none;
  border-radius: 25px;
  padding: 10px 30px 10px 20px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  color: #ffffff;
  background: ${props => props.color};
  background-image: url(${WhiteArrowDown});
  background-repeat: no-repeat;
  background-position: 90% 50%;
  &:disabled {
    background-image: none;
    text-align: center;
  }
`;

export const Option = styled.option`
  background: ${props => props.color};
`;

export const Link = styled(RouterLink)`
  text-decoration: none;
  color: #515974;
`;
