import React from "react";

import Modal from "components/Modal";

import * as S from "./styled";

const SelectExpertModal = ({
  handleCloseModal,
  showModal,
  experts,
  handleExpertSelect
}) => {
  const renderExperts = () => {
    return experts.map(expert => (
      <S.ExpertItem
        key={expert._id}
        onClick={() => handleExpertSelect(expert._id)}
      >
        <span>{`${expert.firstName} ${expert.lastName}`}</span>
        <div>
          <img src={expert.avatarURL || ""} />
        </div>
      </S.ExpertItem>
    ));
  };
  return (
    <Modal isOpen={showModal} onRequestClose={handleCloseModal}>
      <S.ModalTitle>
        <span>Select Expert</span>
        <S.CloseModalBtn onClick={handleCloseModal}>x</S.CloseModalBtn>
      </S.ModalTitle>
      <S.ExpertsContainer>{renderExperts()}</S.ExpertsContainer>
    </Modal>
  );
};

export default SelectExpertModal;
