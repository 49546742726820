import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
`;

export const Marker = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  cursor: move;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  border: 1px solid white;
  font-weight: bold;
  -webkit-print-color-adjust: exact;
`;
export const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 340px;
  height: 400px;
  box-sizing: border-box
  background: #e4ebf7;
  margin: 0 20px;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 10px 10px 42px -1px rgba(0, 0, 0, 0.75);
  -webkit-print-color-adjust: exact;
  & img.avatar {
    width: 50px;
    height: 50px;
  }
`;

export const ExpertInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const Avatar = styled.img`
  flex-shrink: 0;
  border-radius: 100%;
  margin-right: 20px;
`;

export const Name = styled.span`
  font-size: 20px;
`;
export const SubmitBtn = styled.button`
  margin-top: 25px;
  height: 30px;
  background: linear-gradient(90deg, #00c999, #00e67d);
  border: 1px solid transparent;
  transition: background 0.5s;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  line-height: 12px;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  font-family: Roboto, sans-serif;
  width: 100px;
  align-self: flex-end;
  &:hover {
    background: linear-gradient(90deg, #00e67d, #33ef9d);
  }
`;

export const TextArea = styled.textarea`
  resize: none;
  border-radius: 5px;
  min-height: 250px;
  padding: 15px;
  height: 100%;
  outline: none;
`;
