import React from "react";

import * as S from "./styled";

function MenuItem({ active, badgeNumber, title, icon, isOpen }) {
  return (
    <S.Wrapper active={active}>
      <S.LinkInfo>
        <S.MenuIcon>
          <img src={icon} alt="Menu Icon" />
        </S.MenuIcon>
        <S.MenuTitle isOpen={isOpen}>{title}</S.MenuTitle>
      </S.LinkInfo>
      {badgeNumber && <S.Badge>{badgeNumber}</S.Badge>}
    </S.Wrapper>
  );
}

export default MenuItem;
