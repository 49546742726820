import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import LoadingSpinner from "components/LoadingSpinner";

import { actions as projectsActions } from "redux/projects";

import Table from "./components/Table";
import SelectExpertModal from "./components/SelectExpertModal";

import * as S from "./styled";

function ProjectAssignment() {
  const dispatch = useDispatch();
  const [showExpertModal, setShowExpertModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const { loadingProjects, usersList } = useSelector(({ projects, users }) => ({
    loadingProjects: projects.loadingProjects,
    usersList: users.usersList
  }));

  const experts = usersList.filter(user => user.role === "expert");

  const handleOpenModal = projectId => {
    setShowExpertModal(true);
    setSelectedProject(projectId);
  };

  const handleCloseModal = () => {
    setShowExpertModal(false);
    setSelectedProject("");
  };

  const handleExpertSelect = expertId => {
    dispatch(
      projectsActions.assignExpertRequest({
        projectId: selectedProject,
        expertId,
        cb: handleCloseModal
      })
    );
    console.log(selectedProject, expertId);
  };

  useEffect(() => {
    dispatch(projectsActions.getProjectsRequest());
  }, [dispatch]);

  if (loadingProjects) {
    return (
      <S.SpinnerWrapper>
        <LoadingSpinner />
      </S.SpinnerWrapper>
    );
  }

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>Projects Assignment</S.Title>
      </S.Header>
      <Table onOpenModal={handleOpenModal} experts={experts} />
      <SelectExpertModal
        showModal={showExpertModal}
        handleCloseModal={handleCloseModal}
        handleExpertSelect={handleExpertSelect}
        experts={experts}
      />
    </S.Wrapper>
  );
}

export default ProjectAssignment;
