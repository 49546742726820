import http from "../index";

export const createProject = values => http.post("/projects", values);

export const getProjects = () => http.get("/projects");

export const getProjectGrids = projectId =>
  http.get(`/projects/${projectId}/grids`);

export const saveGrids = (grids, projectId, gridsToDelete) =>
  http.post("/projects/grids", { grids, projectId, gridsToDelete });

export const addProjectLogo = (fileURL, projectId) =>
  http.patch(`/projects/${projectId}/logo`, { fileURL });

export const editProjectAnalyseTitle = (analyseTitle, projectId) =>
  http.patch(`/projects/${projectId}/analyse-title`, { analyseTitle });

export const searchProjects = queryString =>
  http.get(`/projects/search/${queryString}`);

export const editProject = (projectId, values) =>
  http.patch(`/projects/${projectId}`, { values });

export const changeProjectStatus = (projectId, newStatus) =>
  http.patch(`/projects/${projectId}`, { values: { status: newStatus } });

export const getGoogleAuthLink = projectId =>
  http.get(`/ga/authLink/${projectId}`);

export const addGoogleUser = (projectId, code) =>
  http.post(`/ga/${projectId}`, { code });

export const getAnalyticsAccs = projectId =>
  http.get(`/ga/${projectId}/accounts`);

export const getAnalyticsProps = (projectId, accountId) =>
  http.get(`/ga/${projectId}/accounts/${accountId}/webproperties`);

export const getAnalyticsViews = (projectId, accountId, propId) =>
  http.get(
    `/ga/${projectId}/accounts/${accountId}/webproperties/${propId}/profiles`
  );

export const getStatusOverview = () => http.get("/projects/statusOverview");

export const getGAMetrics = (projectId, queryString) =>
  http.get(`/ga/${projectId}/metrics/${queryString}`);

export const getPageviewsGAReport = projectId =>
  http.get(`/ga/${projectId}/viewsCustomReport`);

export const getResolutionGAReport = projectId =>
  http.get(`/ga/${projectId}/resolutionCustomReport`);

export const assignExpert = (projectId, expertId) =>
  http.post(`/projects/assignExpert`, { projectId, expertId });

export const takeScreenshot = URL =>
  http.post(`/projects/pageScreenshot`, { URL });

export const uploadProjectLogo = formData =>
  http.post("/upload/project-logo", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
export const uploadImage = formData =>
  http.post("/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
