import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";

import { actions as projectsActions } from "redux/projects";

import Modal from "components/Modal";

import * as S from "./styled";

const EditUserModal = ({ handleCloseModal, showModal, projectId }) => {
  const dispatch = useDispatch();
  const { analyticsAccs, analyticsProps, analyticsViews } = useSelector(
    ({ projects }) => ({
      analyticsAccs: projects.analyticsAccs,
      analyticsProps: projects.analyticsProps,
      analyticsViews: projects.analyticsViews
    })
  );
  const handleSubmit = values => {
    dispatch(
      projectsActions.saveProjectViewIdRequest({
        projectId,
        viewId: values.view,
        cb: handleCloseModal
      })
    );
  };

  useEffect(() => {
    dispatch(projectsActions.getAnalyticsAccsRequest({ projectId }));
  }, [dispatch]);

  const handleAccChange = value => {
    if (value) {
      dispatch(
        projectsActions.getAnalyticsPropsRequest({
          projectId,
          accountId: value
        })
      );
    }
  };

  const handlePropChange = (accountId, value) => {
    if (value) {
      dispatch(
        projectsActions.getAnalyticsViewsRequest({
          projectId,
          accountId,
          propId: value
        })
      );
    }
  };

  return (
    <Modal isOpen={showModal} onRequestClose={handleCloseModal}>
      <S.ModalTitle>
        <span>Set Analytics View ID</span>
        <S.CloseModalBtn onClick={handleCloseModal}>x</S.CloseModalBtn>
      </S.ModalTitle>
      <Formik onSubmit={handleSubmit}>
        {props => (
          <S.Form>
            <S.FieldTitle>Step 1: Choose Analytics Account</S.FieldTitle>
            <S.Field
              name="account"
              as="select"
              value={props.values.account}
              onChange={e => {
                props.setFieldValue("account", e.target.value, true);
                handleAccChange(e.target.value);
              }}
            >
              <option value="">Select account</option>
              {analyticsAccs.map(acc => (
                <option value={acc.id} key={acc.id}>
                  {acc.name}
                </option>
              ))}
            </S.Field>
            {props.values.account && !!analyticsProps.length && (
              <>
                <S.FieldTitle>
                  Step 2: Choose Analytics Web Property
                </S.FieldTitle>
                <S.Field
                  name="webproperty"
                  as="select"
                  value={props.values.webproperty}
                  onChange={e => {
                    props.setFieldValue("webproperty", e.target.value, true);
                    handlePropChange(props.values.account, e.target.value);
                  }}
                >
                  <option value="">Select web property</option>
                  {analyticsProps.map(prop => (
                    <option value={prop.id} key={prop.id}>
                      {prop.name}
                    </option>
                  ))}
                </S.Field>
              </>
            )}
            {props.values.account &&
              !!analyticsProps.length &&
              props.values.webproperty &&
              !!analyticsViews.length && (
                <>
                  <S.FieldTitle>Step 3: Choose Analytics View</S.FieldTitle>
                  <S.Field
                    name="view"
                    as="select"
                    value={props.values.view}
                    onChange={e => {
                      props.setFieldValue("view", e.target.value, true);
                    }}
                  >
                    <option value="">Select view</option>
                    {analyticsViews.map(view => (
                      <option value={view.id} key={view.id}>
                        {view.name}
                      </option>
                    ))}
                  </S.Field>
                </>
              )}
            <S.SubmitButton type="submit">SUBMIT</S.SubmitButton>
          </S.Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditUserModal;
