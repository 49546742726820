import React from "react";

import MenuItem from "../MenuItem";

import libraryIcon from "assets/images/libraryIcon.png";
import helpIcon from "assets/images/helpIcon.png";

import * as S from "./styled";

function MoreInfoLinks({ isOpen }) {
  return (
    <div>
      <S.MenuLink href="https://somelink.com" target="_blank">
        <MenuItem title="Bibliothek" icon={libraryIcon} isOpen={isOpen} />
      </S.MenuLink>
      <S.MenuLink href="https://somelink.com" target="_blank">
        <MenuItem title="Hilfecenter" icon={helpIcon} isOpen={isOpen} />
      </S.MenuLink>
    </div>
  );
}

export default MoreInfoLinks;
