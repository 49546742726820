import React, { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { actions as projectsActions } from "redux/projects";

import analysisReportLogo from "assets/images/analysisReportLogo.png";

import Grid from "../Grid";

import * as S from "./styled";
import LoadingSpinner from "components/LoadingSpinner";

function GridManager({ isReport }) {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const {
    grids,
    loadingGrids,
    projectsList,
    loadingProjects,
    profile: { role }
  } = useSelector(({ projects, auth }) => ({
    projectsList: projects.projectsList,
    loadingProjects: projects.loadingProjects,
    grids: projects.grids,
    loadingGrids: projects.loadingGrids,
    profile: auth.profile
  }));
  const project = projectsList.find(proj => proj._id === projectId);

  const addGridToTop = () => {
    dispatch(projectsActions.createNewGrid());
  };
  const addGridByIndex = index => () => {
    dispatch(projectsActions.createNewGridByIndex(index));
  };

  useEffect(() => {
    dispatch(projectsActions.getProjectsRequest());
    dispatch(projectsActions.getProjectGridsRequest({ projectId }));
  }, [dispatch, projectId]);

  const renderGrids = () => {
    if (!grids.length && role === "user") {
      return (
        <S.NoReportMsg>
          <span>There is no report yet</span>
        </S.NoReportMsg>
      );
    }
    return grids.map((grid, index) => (
      <Fragment key={grid.id}>
        <Grid
          gridId={grid.id}
          size={grid.size}
          style={grid.style}
          blocks={grid.blocks}
          blocksContent={grid.blocksContent}
          projectId={projectId}
          isReport={isReport}
          role={role}
        />
        {!isReport && role !== "user" && (
          <S.AddGridBlock onClick={addGridByIndex(index)}>
            <S.AddGridTitle>Take a new grid!</S.AddGridTitle>
            <S.AddGridButton>+</S.AddGridButton>
          </S.AddGridBlock>
        )}
      </Fragment>
    ));
  };

  if (loadingGrids || loadingProjects) {
    return (
      <S.SpinnerWrapper>
        <LoadingSpinner />
      </S.SpinnerWrapper>
    );
  }

  return (
    <S.Wrapper id="grid-manager-wait">
      {isReport && project && (
        <S.PDFCover>
          <S.AnalysisReportLogoBlock>
            <img src={analysisReportLogo} />
          </S.AnalysisReportLogoBlock>
          <S.AnalysisProjectLogoBlock>
            <img src={project.logo} />
          </S.AnalysisProjectLogoBlock>
        </S.PDFCover>
      )}
      {isReport && project && (
        <S.Header>
          <span>{`${project.analyseTitle} Report`}</span>
          <S.ProjectLink target="_blank" href={project.page}>
            {project.page}
          </S.ProjectLink>
        </S.Header>
      )}
      {!isReport && role !== "user" && (
        <S.AddGridBlock onClick={addGridToTop}>
          <S.AddGridTitle>Take a new grid!</S.AddGridTitle>
          <S.AddGridButton>+</S.AddGridButton>
        </S.AddGridBlock>
      )}
      {renderGrids()}
    </S.Wrapper>
  );
}

export default GridManager;
