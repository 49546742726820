import { handleActions, createActions } from "redux-actions";

import initialState, * as handlers from "./handlers";

export const actions = createActions({
  CREATE_PROJECT_REQUEST: undefined,
  CREATE_PROJECT_SUCCESS: undefined,
  CREATE_PROJECT_FAILURE: undefined,

  GET_PROJECTS_REQUEST: undefined,
  GET_PROJECTS_SUCCESS: undefined,
  GET_PROJECTS_FAILURE: undefined,

  GET_PROJECT_GRIDS_REQUEST: undefined,
  GET_PROJECT_GRIDS_SUCCESS: undefined,
  GET_PROJECT_GRIDS_FAILURE: undefined,

  SAVE_GRIDS_REQUEST: undefined,
  SAVE_GRIDS_SUCCESS: undefined,
  SAVE_GRIDS_FAILURE: undefined,

  ADD_PROJECT_LOGO_REQUEST: undefined,
  ADD_PROJECT_LOGO_SUCCESS: undefined,
  ADD_PROJECT_LOGO_FAILURE: undefined,

  EDIT_PROJECT_ANALYSE_TITLE_REQUEST: undefined,
  EDIT_PROJECT_ANALYSE_TITLE_SUCCESS: undefined,
  EDIT_PROJECT_ANALYSE_TITLE_FAILURE: undefined,

  SEARCH_PROJECTS_REQUEST: undefined,
  SEARCH_PROJECTS_SUCCESS: undefined,
  SEARCH_PROJECTS_FAILURE: undefined,

  EDIT_PROJECT_REQUEST: undefined,
  EDIT_PROJECT_SUCCESS: undefined,
  EDIT_PROJECT_FAILURE: undefined,

  CHANGE_PROJECT_STATUS_REQUEST: undefined,
  CHANGE_PROJECT_STATUS_SUCCESS: undefined,
  CHANGE_PROJECT_STATUS_FAILURE: undefined,

  GET_GOOGLE_AUTH_LINK_REQUEST: undefined,
  GET_GOOGLE_AUTH_LINK_SUCCESS: undefined,
  GET_GOOGLE_AUTH_LINK_FAILURE: undefined,

  ADD_GOOGLE_USER_REQUEST: undefined,
  ADD_GOOGLE_USER_SUCCESS: undefined,
  ADD_GOOGLE_USER_FAILURE: undefined,

  GET_ANALYTICS_ACCS_REQUEST: undefined,
  GET_ANALYTICS_ACCS_SUCCESS: undefined,
  GET_ANALYTICS_ACCS_FAILURE: undefined,

  GET_ANALYTICS_PROPS_REQUEST: undefined,
  GET_ANALYTICS_PROPS_SUCCESS: undefined,
  GET_ANALYTICS_PROPS_FAILURE: undefined,

  GET_ANALYTICS_VIEWS_REQUEST: undefined,
  GET_ANALYTICS_VIEWS_SUCCESS: undefined,
  GET_ANALYTICS_VIEWS_FAILURE: undefined,

  SAVE_PROJECT_VIEW_ID_REQUEST: undefined,
  SAVE_PROJECT_VIEW_ID_SUCCESS: undefined,
  SAVE_PROJECT_VIEW_ID_FAILURE: undefined,

  SAVE_GRID_IMAGE_CONTENT_REQUEST: undefined,
  SAVE_GRID_IMAGE_CONTENT_SUCCESS: undefined,
  SAVE_GRID_IMAGE_CONTENT_FAILURE: undefined,

  SAVE_GRID_G_A_CONTENT_REQUEST: undefined,
  SAVE_GRID_G_A_CONTENT_SUCCESS: undefined,
  SAVE_GRID_G_A_CONTENT_FAILURE: undefined,

  SAVE_GRID_PAGEVIEWS_G_A_REPORT_REQUEST: undefined,
  SAVE_GRID_PAGEVIEWS_G_A_REPORT_SUCCESS: undefined,
  SAVE_GRID_PAGEVIEWS_G_A_REPORT_FAILURE: undefined,

  SAVE_GRID_RESOLUTION_G_A_REPORT_REQUEST: undefined,
  SAVE_GRID_RESOLUTION_G_A_REPORT_SUCCESS: undefined,
  SAVE_GRID_RESOLUTION_G_A_REPORT_FAILURE: undefined,

  GET_STATUS_OVERVIEW_REQUEST: undefined,
  GET_STATUS_OVERVIEW_SUCCESS: undefined,
  GET_STATUS_OVERVIEW_FAILURE: undefined,

  ASSIGN_EXPERT_REQUEST: undefined,
  ASSIGN_EXPERT_SUCCESS: undefined,
  ASSIGN_EXPERT_FAILURE: undefined,

  TAKE_SCREENSHOT_REQUEST: undefined,
  TAKE_SCREENSHOT_SUCCESS: undefined,
  TAKE_SCREENSHOT_FAILURE: undefined,

  CREATE_NEW_GRID: undefined,

  CREATE_NEW_GRID_BY_INDEX: undefined,

  COPY_GRID: undefined,

  DELETE_GRID: undefined,

  CHANGE_GRID_SIZE: undefined,

  SAVE_GRID_CONTENT: undefined,

  CLEAR_GRIDS: undefined,

  ADD_MARKER_TO_GRID_ITEM: undefined,

  CHANGE_MARKER: undefined
});

const reducer = handleActions(
  new Map([
    [actions.createProjectRequest, handlers.createProjectRequest],
    [actions.createProjectSuccess, handlers.createProjectSuccess],
    [actions.createProjectFailure, handlers.createProjectFailure],

    [actions.getProjectsRequest, handlers.getProjectsRequest],
    [actions.getProjectsSuccess, handlers.getProjectsSuccess],
    [actions.getProjectsFailure, handlers.getProjectsFailure],

    [actions.getProjectGridsRequest, handlers.getProjectGridsRequest],
    [actions.getProjectGridsSuccess, handlers.getProjectGridsSuccess],
    [actions.getProjectGridsFailure, handlers.getProjectGridsFailure],
    [actions.assignExpertRequest, handlers.assignExpertRequest],
    [actions.assignExpertSuccess, handlers.assignExpertSuccess],
    [actions.assignExpertFailure, handlers.assignExpertFailure],
    [actions.saveGridsRequest, handlers.saveGridsRequest],
    [actions.saveGridsSuccess, handlers.saveGridsSuccess],
    [actions.saveGridsFailure, handlers.saveGridsFailure],

    [actions.addProjectLogoRequest, handlers.addProjectLogoRequest],
    [actions.addProjectLogoSuccess, handlers.addProjectLogoSuccess],
    [actions.addProjectLogoFailure, handlers.addProjectLogoFailure],

    [actions.searchProjectsRequest, handlers.searchProjectsRequest],
    [actions.searchProjectsSuccess, handlers.searchProjectsSuccess],
    [actions.searchProjectsFailure, handlers.searchProjectsFailure],

    [actions.editProjectRequest, handlers.editProjectRequest],
    [actions.editProjectSuccess, handlers.editProjectSuccess],
    [actions.editProjectFailure, handlers.editProjectFailure],

    [actions.changeProjectStatusRequest, handlers.changeProjectStatusRequest],
    [actions.changeProjectStatusSuccess, handlers.changeProjectStatusSuccess],
    [actions.changeProjectStatusFailure, handlers.changeProjectStatusFailure],

    [actions.getGoogleAuthLinkRequest, handlers.getGoogleAuthLinkRequest],
    [actions.getGoogleAuthLinkSuccess, handlers.getGoogleAuthLinkSuccess],
    [actions.getGoogleAuthLinkFailure, handlers.getGoogleAuthLinkFailure],

    [actions.addGoogleUserRequest, handlers.addGoogleUserRequest],
    [actions.addGoogleUserSuccess, handlers.addGoogleUserSuccess],
    [actions.addGoogleUserFailure, handlers.addGoogleUserFailure],

    [actions.getAnalyticsAccsRequest, handlers.getAnalyticsAccsRequest],
    [actions.getAnalyticsAccsSuccess, handlers.getAnalyticsAccsSuccess],
    [actions.getAnalyticsAccsFailure, handlers.getAnalyticsAccsFailure],

    [actions.getAnalyticsPropsRequest, handlers.getAnalyticsPropsRequest],
    [actions.getAnalyticsPropsSuccess, handlers.getAnalyticsPropsSuccess],
    [actions.getAnalyticsPropsFailure, handlers.getAnalyticsPropsFailure],

    [actions.getAnalyticsViewsRequest, handlers.getAnalyticsViewsRequest],
    [actions.getAnalyticsViewsSuccess, handlers.getAnalyticsViewsSuccess],
    [actions.getAnalyticsViewsFailure, handlers.getAnalyticsViewsFailure],

    [actions.saveProjectViewIdRequest, handlers.saveProjectViewIdRequest],
    [actions.saveProjectViewIdSuccess, handlers.saveProjectViewIdSuccess],
    [actions.saveProjectViewIdFailure, handlers.saveProjectViewIdFailure],

    [actions.saveGridImageContentRequest, handlers.saveGridImageContentRequest],
    [actions.saveGridImageContentSuccess, handlers.saveGridImageContentSuccess],
    [actions.saveGridImageContentFailure, handlers.saveGridImageContentFailure],

    [actions.saveGridGAContentRequest, handlers.saveGridGAContentRequest],
    [actions.saveGridGAContentSuccess, handlers.saveGridGAContentSuccess],
    [actions.saveGridGAContentFailure, handlers.saveGridGAContentFailure],

    [
      actions.saveGridPageviewsGAReportRequest,
      handlers.saveGridPageviewsGAReportRequest
    ],
    [
      actions.saveGridPageviewsGAReportSuccess,
      handlers.saveGridPageviewsGAReportSuccess
    ],
    [
      actions.saveGridPageviewsGAReportFailure,
      handlers.saveGridPageviewsGAReportFailure
    ],

    [
      actions.saveGridResolutionGAReportRequest,
      handlers.saveGridResolutionGAReportRequest
    ],
    [
      actions.saveGridResolutionGAReportSuccess,
      handlers.saveGridResolutionGAReportSuccess
    ],
    [
      actions.saveGridResolutionGAReportFailure,
      handlers.saveGridResolutionGAReportFailure
    ],

    [actions.getStatusOverviewRequest, handlers.getStatusOverviewRequest],
    [actions.getStatusOverviewSuccess, handlers.getStatusOverviewSuccess],
    [actions.getStatusOverviewFailure, handlers.getStatusOverviewFailure],

    [
      actions.editProjectAnalyseTitleRequest,
      handlers.editProjectAnalyseTitleRequest
    ],
    [
      actions.editProjectAnalyseTitleSuccess,
      handlers.editProjectAnalyseTitleSuccess
    ],
    [
      actions.editProjectAnalyseTitleFailure,
      handlers.editProjectAnalyseTitleFailure
    ],

    [actions.assignExpertRequest, handlers.assignExpertRequest],
    [actions.assignExpertSuccess, handlers.assignExpertSuccess],
    [actions.assignExpertFailure, handlers.assignExpertFailure],

    [actions.takeScreenshotRequest, handlers.takeScreenshotRequest],
    [actions.takeScreenshotSuccess, handlers.takeScreenshotSuccess],
    [actions.takeScreenshotFailure, handlers.takeScreenshotFailure],

    [actions.addMarkerToGridItem, handlers.addMarkerToGridItem],

    [actions.changeMarker, handlers.changeMarker],

    [actions.createNewGrid, handlers.createNewGrid],

    [actions.createNewGridByIndex, handlers.createNewGridByIndex],

    [actions.copyGrid, handlers.copyGrid],

    [actions.deleteGrid, handlers.deleteGrid],

    [actions.changeGridSize, handlers.changeGridSize],

    [actions.saveGridContent, handlers.saveGridContent],

    [actions.clearGrids, handlers.clearGrids]
  ]),
  initialState
);

export default reducer;
