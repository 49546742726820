import styled from "styled-components";

export const MainWrapper = styled.div`
  display: flex;
  transition: 0.3s;
  min-height: 100vh;
`;

export const ContentWrapper = styled.div`
  padding: 50px 30px;
`;
export const MainContent = styled.div`
  width: 100%;
`;
