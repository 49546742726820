import React, { useState } from "react";
import draftToHtml from "draftjs-to-html";
import ReactHtmlParser from "react-html-parser";

import Modal from "components/Modal";

import WYSIWYG from "../WYSIWYG";
import GAMetrics from "../GAMetrics";

import PageviewsGAReport from "./components/PageviewsGAReport";
import ResolutionGAReport from "./components/ResolutionGAReport";
import Markers from "./components/Markers";

import * as S from "./styled";

const GridItem = ({
  onSave,
  onSaveImage,
  initialBlockContent,
  onSaveGA,
  onSavePageviewsGAReport,
  onSaveResolutionGAReport,
  isReport,
  onSaveMarkersScreenshot,
  onAddMarker,
  onChangeMarker,
  role
}) => {
  const [isWYSIWYGOpen, setIsWYSIWYGOpen] = useState(false);
  const [isGAOpen, setIsGAOpen] = useState(false);
  const [URLInput, setURLInput] = useState("");
  const [isTypeModalOpen, setIsTypeModalOpen] = useState(false);
  const [isURLInputOpen, setIsURLInputOpen] = useState(false);
  const [blockContent, setBlockContent] = useState(
    initialBlockContent && initialBlockContent.content
  );

  const renderInitialContent = () => {
    const handleAddMarker = e => {
      e.stopPropagation();
      onAddMarker();
    };
    const renderGA = values => {
      const names = {
        "ga:users": "Users",
        "ga:timeOnPage": "Time On Page",
        "ga:pageviews": "Page Views",
        "ga:uniquePageviews": "Unique Page Views",
        "ga:pageLoadTime": "Page Load Time"
      };
      const list = [];
      Object.entries(values).forEach(([key, value]) => {
        list.push(<li key={key}>{`${names[key]}: ${value}`}</li>);
      });
      return list;
    };

    if (initialBlockContent && initialBlockContent.content) {
      if (initialBlockContent.type === "WYSIWYG") {
        return ReactHtmlParser(draftToHtml(initialBlockContent.content));
      }
      if (initialBlockContent.type === "IMAGE") {
        return <img src={initialBlockContent.content.fileURL} alt="Report" />;
      }
      if (initialBlockContent.type === "GA") {
        return <ul>{renderGA(initialBlockContent.content)}</ul>;
      }
      if (initialBlockContent.type === "PAGEVIEWS") {
        return <PageviewsGAReport content={initialBlockContent.content} />;
      }
      if (initialBlockContent.type === "RESOLUTION") {
        return <ResolutionGAReport content={initialBlockContent.content} />;
      }
      if (initialBlockContent.type === "MARKERS") {
        return (
          <S.MarkersWrapper>
            {role !== "user" && !isReport && (
              <S.AddMarkerButton onClick={handleAddMarker}>
                Add marker
              </S.AddMarkerButton>
            )}
            <Markers
              content={initialBlockContent.content}
              onChangeMarker={onChangeMarker}
              isReport={isReport}
            />
          </S.MarkersWrapper>
        );
      }
    }

    return (
      <S.AddContentBtn>
        {role === "user" ? "Work in progress..." : "+"}
      </S.AddContentBtn>
    );
  };

  const handleOpenWYSIWYG = () => {
    setIsWYSIWYGOpen(true);
    setIsTypeModalOpen(false);
  };
  const handleCloseWYSIWYG = () => {
    setIsWYSIWYGOpen(false);
    setBlockContent(initialBlockContent && initialBlockContent.content);
  };
  const handleOpenGA = () => {
    setIsGAOpen(true);
    setIsTypeModalOpen(false);
  };
  const handleCloseGA = () => {
    setIsGAOpen(false);
    setBlockContent(initialBlockContent && initialBlockContent.content);
  };
  const handleOpenURLInput = () => {
    setIsURLInputOpen(true);
    setIsTypeModalOpen(false);
  };
  const handleCloseURLInput = () => {
    setIsURLInputOpen(false);
    setBlockContent(initialBlockContent && initialBlockContent.content);
  };

  const handleSave = () => {
    onSave(blockContent, "WYSIWYG");
    setIsWYSIWYGOpen(false);
  };

  const handleChangeFile = e => {
    const [file] = e.target.files;
    const formData = new FormData();

    formData.append("image", file);

    onSaveImage(formData, "IMAGE", () => setIsTypeModalOpen(false));
  };

  const getWYSIWYGInitial = () => {
    if (initialBlockContent && initialBlockContent.type === "WYSIWYG") {
      return initialBlockContent.content;
    }
    return null;
  };

  const handleSavePageviewsGAReport = () => {
    onSavePageviewsGAReport("PAGEVIEWS", () => setIsTypeModalOpen(false));
  };
  const handleSaveResolutionGAReport = () => {
    onSaveResolutionGAReport("RESOLUTION", () => setIsTypeModalOpen(false));
  };
  const handleSaveMarkersScreenshot = () => {
    onSaveMarkersScreenshot(URLInput, "MARKERS", () =>
      setIsURLInputOpen(false)
    );
  };

  const handleOpenChooseModal = () => {
    if (role === "user") {
      return;
    }
    setIsTypeModalOpen(true);
  };

  return (
    <>
      <S.Wrapper onClick={handleOpenChooseModal} isReport={isReport}>
        {renderInitialContent()}
      </S.Wrapper>
      <Modal
        isOpen={isTypeModalOpen}
        onRequestClose={() => setIsTypeModalOpen(false)}
      >
        <S.TypesWrapper>
          <S.TypeBtn onClick={handleOpenWYSIWYG}>Editor</S.TypeBtn>
          <S.TypeBtn className="custom-file-upload">
            <label>
              Image
              <input type="file" onChange={handleChangeFile} />
            </label>
          </S.TypeBtn>
          <S.TypeBtn onClick={handleOpenGA}>Analytics Data</S.TypeBtn>
          <S.TypeBtn onClick={handleSavePageviewsGAReport}>
            GA Page Views Report
          </S.TypeBtn>
          <S.TypeBtn onClick={handleSaveResolutionGAReport}>
            GA Resolution Report
          </S.TypeBtn>
          <S.TypeBtn onClick={handleOpenURLInput}>Page Markers</S.TypeBtn>
        </S.TypesWrapper>
      </Modal>
      <Modal isOpen={isWYSIWYGOpen} onRequestClose={handleCloseWYSIWYG}>
        <WYSIWYG
          defaultValue={getWYSIWYGInitial()}
          onChange={setBlockContent}
        />
        <S.ActionButtonContainer>
          <S.ActionButton onClick={handleCloseWYSIWYG}>Cancel</S.ActionButton>
          <S.ActionButton onClick={handleSave}>Save</S.ActionButton>
        </S.ActionButtonContainer>
      </Modal>
      <Modal isOpen={isGAOpen} onRequestClose={handleCloseGA}>
        <GAMetrics onClose={handleCloseGA} onSave={onSaveGA} />
      </Modal>
      <Modal isOpen={isURLInputOpen} onRequestClose={handleCloseURLInput}>
        <S.InputURLWrapper>
          <S.InputURLTitle>Enter URL to take screenshot</S.InputURLTitle>
          <S.InputURL
            value={URLInput}
            onChange={e => setURLInput(e.target.value)}
          />
          <S.InputURLSubmitBtn onClick={handleSaveMarkersScreenshot}>
            Take screenshot
          </S.InputURLSubmitBtn>
        </S.InputURLWrapper>
      </Modal>
    </>
  );
};

export default GridItem;
