import styled from "styled-components";
import { Form as FormikForm, Field as FormikField, ErrorMessage } from "formik";

export const ModalTitle = styled.div`
  font-size: 22px;
  line-height: 22px;
  font-weight: bold;
  color: #515974;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 12px 0px;
  border-bottom: 2px solid #e1e7f0;
`;

export const CloseModalBtn = styled.button`
  background: transparent;
  border: none;
  outline: none;
  font-size: 22px;
  color: #515974;
  cursor: pointer;
`;

export const Form = styled(FormikForm)`
  padding: 16px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #e6e8eb;
  border-radius: 12px;
  margin-top: 16px;
`;

export const Field = styled(FormikField)`
  height: 30px;
  border: 1px solid #e1e3e6;
  outline: none;
  font-size: 14px;
  background: transparent;
  color: #3e3e3e;
  border-radius: 8px;
  padding: 0 12px;
  margin-bottom: 16px;
  &::placeholder {
    font-style: italic;
    color: #e1e3e;
  }
`;

export const FieldTitle = styled.span`
  font-size: 13px;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 7px;
  margin-top: 10px;
  color: #363840;
`;

export const ErrMessage = styled(ErrorMessage)`
  font-size: 12px;
  line-height: 20px;
  margin-top: -10px;
  color: red;
`;

export const SubmitButton = styled.button`
  height: 35px;
  background: linear-gradient(90deg, #00c999, #00e67d);
  border: 1px solid transparent;
  transition: background 0.5s;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  line-height: 12px;
  border-radius: 16px;
  cursor: pointer;
  outline: none;
  margin-top: 15px;
  font-family: Roboto, sans-serif;
  width: 140px;
  align-self: flex-end;
  &:hover {
    background: linear-gradient(90deg, #00e67d, #33ef9d);
  }
`;
