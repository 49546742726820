import styled from "styled-components";

export const AnylyseInfoBlock = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.logo ? "150px 1fr" : "1fr")};
`;

export const ProjectLogoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6daedb;
  height: 100px;
`;

export const ProjectLogo = styled.div`
  border-radius: 100%;
  overflow: hidden;
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  background: #ffffff;
  & img {
    height: 100%;
  }
`;

export const AnalyseInfo = styled.div`
  background-color: #c5c1c1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
`;

export const AnalyseTitle = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const TitleEdit = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: -7px;
  margin-right: 18px;
`;

export const TitleLabel = styled.div`
  border-radius: 5px;
  background-color: #1b2133;
  padding: 10px 12px;
  font-size: 28px;
  font-weight: bold;
  color: #f2f6fc;
  line-height: 28px;
  position: relative;
  bottom: -5px;
`;

export const EditButton = styled.div`
  border-radius: 5px;
  border: solid 0.7px #f2f6fc;
  background-color: #ec6446;
  padding: 4px 10px;
  font-size: 15px;
  font-weight: 300;
  font-style: italic;
  line-height: 15px;
  color: #f2f6fc;
  align-self: center;
  z-index: 2;
`;

export const Title = styled.input`
  font-size: 32px;
  width: 100%;
  font-weight: bold;
  color: #f2f6fc;
  background: transparent;
  border: none;
`;

export const AnalyseActions = styled.div`
  display: flex;
  align-items: center;
`;
export const PDFLink = styled.a`
  margin-right: 15px;
  display: inline-block;
`;

export const LogoUploadWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;

  & input[type="file"] {
    font-size: 0;
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

export const AnalyseActionButton = styled.button`
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-right: 15px;
  background-color: rgb(27, 33, 51, 0.66);
  min-width: 180px;
  padding: 10px 27px;
  font-size: 20px;
  font-weight: bold;
  color: #e4ebf7;
  line-height: 20px;
  &:disabled {
    background-color: rgb(27, 33, 51, 0.33);
    cursor: default;
  }
  &:last-child {
    margin-right: 0;
  }
  &.logo-uploaded {
    background: #32a852;
  }
`;
