import styled from "styled-components";

export const SpinnerWrapper = styled.div`
  position: relative;
  display: block;
  height: 200px;
  width: 100%;
`;

export const PDFCover = styled.div`
  height: 100vh;
  background-color: #404750;
  -webkit-print-color-adjust: exact;
  width: 100%;
`;
export const NoReportMsg = styled.div`
  height: 200px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #515974;
`;
export const ProjectLink = styled.a`
  text-decoration: underline;
  font-size: 16px;
  font-weight: bold;
  color: black;
`;
export const AnalysisReportLogoBlock = styled.div`
  height: 60vh;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
export const AnalysisProjectLogoBlock = styled.div`
  height: 15vh;
  display: flex;
  width: 100%;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  -webkit-print-color-adjust: exact;
  background: white;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Header = styled.div`
  width: 100%;
  padding: 30px;
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  & > img {
    width: 70px;
  }
  & > span {
    font-size: 30px;
  }
`;

export const AddGridBlock = styled.div`
  display: flex;
  align-items: center;
  margin: 25px 0;
  cursor: pointer;
`;

export const AddGridTitle = styled.span`
  font-size: 18px;
  font-weight: 400;
  color: #a8adb7;
`;

export const AddGridButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  font-size: 40px;
  color: #555864;
  margin-left: 15px;
  cursor: pointer;
`;
