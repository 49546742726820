import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { actions as authActions } from "redux/auth";
import { actions as usersActions } from "redux/users";

import SearchArea from "../SearchArea";

import * as S from "./styled";

function Header() {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    profile: { role, hasNotification }
  } = useSelector(({ auth }) => ({
    profile: auth.profile
  }));

  const handleLogout = () => {
    dispatch(authActions.logout());
  };

  const clearNotification = () => {
    dispatch(usersActions.clearNotificationRequest());
    if (role === "admin") {
      history.push("/app/projects/?projectStatus=complete");
    } else {
      history.push("/app/projects");
    }
  };

  return (
    <S.Wrapper>
      <SearchArea />
      <S.HeaderActions>
        {role === "admin" && (
          <S.ActionButton
            onClick={() => history.push("accounts/")}
            title="Accounts"
          >
            <i className="fas fa-users" />
          </S.ActionButton>
        )}
        <S.ActionButton onClick={clearNotification}>
          <S.Bell
            className={`fas fa-bell ${hasNotification ? "blinks" : ""}`}
          />
        </S.ActionButton>
        <S.ActionButton onClick={handleLogout} title="Log Out">
          <i className="fas fa-sign-out-alt" />
        </S.ActionButton>
      </S.HeaderActions>
    </S.Wrapper>
  );
}

export default Header;
