import { handleActions, createActions } from "redux-actions";

import initialState, * as handlers from "./handlers";

export const actions = createActions({
  AUTHENTICATION_REQUEST: undefined,
  AUTHENTICATION_SUCCESS: undefined,
  AUTHENTICATION_FAILURE: undefined,

  GET_PROFILE_REQUEST: undefined,
  GET_PROFILE_SUCCESS: undefined,
  GET_PROFILE_FAILURE: undefined,

  CHANGE_PROFILE_AVATAR: undefined,

  CLEAR_NOTIFICATION: undefined,

  LOGOUT: undefined
});

const reducer = handleActions(
  new Map([
    [actions.authenticationRequest, handlers.authenticationRequest],
    [actions.authenticationSuccess, handlers.authenticationSuccess],
    [actions.authenticationFailure, handlers.authenticationFailure],

    [actions.getProfileRequest, handlers.getProfileRequest],
    [actions.getProfileSuccess, handlers.getProfileSuccess],
    [actions.getProfileFailure, handlers.getProfileFailure],

    [actions.changeProfileAvatar, handlers.changeProfileAvatar],

    [actions.clearNotification, handlers.clearNotification],
    [actions.logout, handlers.logout]
  ]),
  initialState
);

export default reducer;
