import { getToken, deleteToken } from "services/storage/auth";

const storageToken = getToken();

const initialState = {
  error: "",
  token: storageToken,
  profile: null,
  isAuthenticated: false
};

export const authenticationRequest = state => ({
  ...state
});

export const authenticationSuccess = (state, { payload }) => ({
  ...state,
  token: payload.token
});

export const authenticationFailure = state => ({
  ...state,
  token: null
});

export const getProfileRequest = state => ({
  ...state
});

export const getProfileSuccess = (state, { payload }) => ({
  ...state,
  profile: payload.profile,
  isAuthenticated: true
});

export const getProfileFailure = state => ({
  ...state,
  token: null,
  profile: null,
  isAuthenticated: false
});

export const logout = state => {
  deleteToken();

  return {
    ...state,
    token: null,
    profile: null,
    isAuthenticated: false
  };
};

export const changeProfileAvatar = (state, { payload }) => ({
  ...state,
  profile: {
    ...state.profile,
    avatarURL: payload
  }
});

export const clearNotification = state => ({
  ...state,
  profile: {
    ...state.profile,
    hasNotification: false
  }
});

export default initialState;
