import styled from "styled-components";

export const MainTitle = styled.span`
  font-size: 20px;
  display: block;
`;
export const SubTitle = styled.span`
  font-size: 13px;
  display: block;
  margin-top: 12px;
`;
export const ActionButtons = styled.div`
  display: flex;
  margin-top: 18px;
`;

export const NoButton = styled.button`
  background: #bababa;
  border: none;
  outline: none;
  border-radius: 15px;
  height: 30px;
  padding: 0 20px;
  margin-right: 20px;
  cursor: pointer;
  color: #ffffff;
  width: 80px;
  &:hover {
    background: red;
  }
`;
export const YesButton = styled.button`
  background: #bababa;
  border: none;
  outline: none;
  border-radius: 15px;
  height: 30px;
  padding: 0 20px;
  margin-right: 20px;
  cursor: pointer;
  color: #ffffff;
  width: 80px;
  &:hover {
    background: green;
  }
`;
