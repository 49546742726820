import styled from "styled-components";
import { Form as FormikForm, Field as FormikField, ErrorMessage } from "formik";

export const SpinnerWrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const Wrapper = styled.div`
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.15);
  background-color: #f2f6fc;
`;

export const Header = styled.div`
  display: flex;
  border-bottom: 2px solid #e1e7f0;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
  color: #515974;
  display: block;
  padding: 14px 30px;
`;

export const ErrMessage = styled(ErrorMessage)`
  font-size: 12px;
  line-height: 20px;
  margin-top: -10px;
  color: red;
`;

export const Form = styled(FormikForm)`
  padding: 20px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  border-radius: 12px;
`;

export const Field = styled(FormikField)`
  height: 30px;
  border: 1px solid #e1e3e6;
  outline: none;
  font-size: 13px;
  background: transparent;
  color: #3e3e3e;
  border-radius: 8px;
  padding: 0 12px;
  margin-bottom: 15px;
  &::placeholder {
    font-style: italic;
    color: #e1e3e;
  }
`;

export const FieldTitle = styled.span`
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 7px;
  margin-top: 10px;
  color: #363840;
`;
export const SubmitButton = styled.button`
  height: 35px;
  background: linear-gradient(90deg, #00c999, #00e67d);
  border: 1px solid transparent;
  transition: background 0.5s;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  line-height: 12px;
  border-radius: 16px;
  cursor: pointer;
  outline: none;
  margin-top: 15px;
  font-family: Roboto, sans-serif;
  width: 140px;
  align-self: flex-end;
  &:hover {
    background: linear-gradient(90deg, #00e67d, #33ef9d);
  }
`;

export const AvatarUploadWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;

  & input[type="file"] {
    font-size: 0;
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

export const Avatar = styled.div`
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  width: 160px;
  height: 160px;

  & img {
    width: 100%;
    height: 100%;
  }
`;
