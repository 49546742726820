import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import AppRouter from "router";

import configureStore from "redux/configureStore";

import "./index.css";

const store = configureStore();
toast.configure({
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true
});

ReactDOM.render(
  <Provider store={store}>
    <AppRouter />
  </Provider>,
  document.getElementById("root")
);
