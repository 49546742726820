import styled from "styled-components";

export const Table = styled.table`
  -webkit-print-color-adjust: exact;
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;

  & tr:nth-child(even) {
    background: rgba(0,0,0,0.1);
  }

  & td {
    padding: 10px;
    border: 1px solid rgba(27,33,51,0.33);
    text-align: center;
    &:first-child {
      width: 30%;
    }
  }
`;

export const TableHead = styled.tr`
  white-space: nowrap;
  font-weight: bold;
  & td {
    padding: 10px;
  }
`;
export const Title = styled.div`
  text-align: center;
  width: 100%;

  & span {
    font-size: 26px;
    color: rgba(27,33,51,0.66);
    font-weight: bold;
  }
`;
