import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { actions as projectsActions } from "redux/projects";

import SetViewIdModal from "../SetViewIdModal";

import * as S from "./styled";

function Header({ currentProject }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (currentProject && !currentProject.token) {
      dispatch(
        projectsActions.getGoogleAuthLinkRequest({
          projectId: currentProject._id
        })
      );
    }
  }, [dispatch]);

  const renderGoogle = () => {
    if (!currentProject.gaViewId && currentProject.token) {
      return (
        <S.SetViewButton onClick={() => setShowModal(true)}>
          Set analytics view ID
        </S.SetViewButton>
      );
    }
    if (currentProject.link) {
      return (
        <S.AddAnalyticsLink href={currentProject.link}>
          Add Analytics
        </S.AddAnalyticsLink>
      );
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <S.Header>
      <S.LeftInfoBlock>
        <S.Analyse>Analyse</S.Analyse>
        <S.ProjectNameBlock>
          <S.ProjectName>{currentProject.name}</S.ProjectName>
        </S.ProjectNameBlock>
        <S.ProjectURL>{currentProject.page}</S.ProjectURL>
      </S.LeftInfoBlock>
      <div>
        {currentProject.viewId && <span>View ID: {currentProject.viewId}</span>}
        {renderGoogle()}
      </div>
      {currentProject.token && (
        <SetViewIdModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          projectId={currentProject._id}
        />
      )}
    </S.Header>
  );
}

export default Header;
