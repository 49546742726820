import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import logo from "assets/images/logo.png";
import avatarThumb from "assets/images/avatar.png";

import * as S from "./styled";
import ExpandArea from "../ExpandArea";
import Menu from "../Menu";
import MoreInfoLinks from "../MoreInfoLinks";

function Sidebar({ onBurgerClick, isOpen }) {
  const history = useHistory();
  const {
    profile: { firstName, lastName, role, avatarURL }
  } = useSelector(({ auth }) => ({ profile: auth.profile }));

  return (
    <S.Sidebar isOpen={isOpen}>
      <S.Header isOpen={isOpen}>
        {isOpen ? <img src={logo} alt="Logo" /> : <div />}
        <S.BurgerMenu className={isOpen && "open"} onClick={onBurgerClick}>
          <div />
          <div />
          <div />
        </S.BurgerMenu>
      </S.Header>
      <S.UserInfo isOpen={isOpen} onClick={() => history.push("/app/profile/")}>
        <S.Avatar isOpen={isOpen}>
          <img src={avatarURL || avatarThumb} alt="avatar" />
        </S.Avatar>
        <S.TextInfo>
          <S.Name>{`${firstName} ${lastName}`}</S.Name>
          <S.Role>{role}</S.Role>
        </S.TextInfo>
      </S.UserInfo>
      <ExpandArea title="Administration" isOpen={isOpen}>
        <Menu isOpen={isOpen} />
      </ExpandArea>
      <ExpandArea title="More Info" isOpen={isOpen}>
        <MoreInfoLinks isOpen={isOpen} />
      </ExpandArea>
    </S.Sidebar>
  );
}

export default Sidebar;
