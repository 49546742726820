import React from "react";
import { useDispatch } from "react-redux";

import { actions as projectsActions } from "redux/projects";

import GridActions from "../GridActions";
import GridItem from "../GridItem";

import * as S from "./styled";

function Grid({
  size,
  style,
  blocks,
  gridId,
  blocksContent,
  projectId,
  isReport,
  role
}) {
  const dispatch = useDispatch();
  const handleChangeSize = (newSize, newStyle, newBlocks) => {
    dispatch(
      projectsActions.changeGridSize({
        id: gridId,
        size: newSize,
        style: newStyle,
        blocks: newBlocks,
        blocksContent: []
      })
    );
  };

  const handleSaveBlockContent = blockIndex => (blockContent, type) => {
    dispatch(
      projectsActions.saveGridContent({
        gridId,
        blockIndex,
        blockContent,
        type
      })
    );
  };

  const handleSaveBlockImageContent = blockIndex => (image, type, cb) => {
    dispatch(
      projectsActions.saveGridImageContentRequest({
        gridId,
        blockIndex,
        image,
        type,
        cb
      })
    );
  };

  const handleSaveGA = blockIndex => (queryString, type, cb) => {
    dispatch(
      projectsActions.saveGridGAContentRequest({
        gridId,
        blockIndex,
        queryString,
        projectId,
        type,
        cb
      })
    );
  };

  const handleSavePageviewsGAReport = blockIndex => (type, cb) => {
    dispatch(
      projectsActions.saveGridPageviewsGAReportRequest({
        gridId,
        blockIndex,
        projectId,
        type,
        cb
      })
    );
  };

  const handleSaveResolutionGAReport = blockIndex => (type, cb) => {
    dispatch(
      projectsActions.saveGridResolutionGAReportRequest({
        gridId,
        blockIndex,
        projectId,
        type,
        cb
      })
    );
  };

  const handleSaveMarkersScreenshot = blockIndex => (URL, type, cb) => {
    dispatch(
      projectsActions.takeScreenshotRequest({
        gridId,
        URL,
        blockIndex,
        projectId,
        type,
        cb
      })
    );
  };

  const handleAddMarker = blockIndex => () => {
    dispatch(
      projectsActions.addMarkerToGridItem({
        gridId,
        blockIndex
      })
    );
  };
  const handleChangeMarker = blockIndex => marker => {
    dispatch(
      projectsActions.changeMarker({
        gridId,
        blockIndex,
        marker
      })
    );
  };

  const handleCopyGrid = () => {
    dispatch(projectsActions.copyGrid({ gridId }));
  };

  const handleDeleteGrid = () => {
    dispatch(projectsActions.deleteGrid({ gridId }));
  };

  const renderGridItems = () => {
    const gridItems = [];

    for (let i = 0; i < blocks; i++) {
      gridItems.push(
        <GridItem
          key={i}
          initialBlockContent={blocksContent[i]}
          onSave={handleSaveBlockContent(i)}
          onSaveImage={handleSaveBlockImageContent(i)}
          onSaveGA={handleSaveGA(i)}
          onSavePageviewsGAReport={handleSavePageviewsGAReport(i)}
          onSaveResolutionGAReport={handleSaveResolutionGAReport(i)}
          onSaveMarkersScreenshot={handleSaveMarkersScreenshot(i)}
          onAddMarker={handleAddMarker(i)}
          onChangeMarker={handleChangeMarker(i)}
          isReport={isReport}
          role={role}
        />
      );
    }

    return gridItems;
  };

  return (
    <S.GridWrapper style={style} isReport={isReport}>
      {!isReport && role !== "user" && (
        <GridActions
          onChangeSize={handleChangeSize}
          initialSize={size}
          handleCopyGrid={handleCopyGrid}
          handleDeleteGrid={handleDeleteGrid}
        />
      )}
      {renderGridItems()}
    </S.GridWrapper>
  );
}

export default Grid;
