import http from "http/index";

export const getToken = () => {
  const tokenItem = localStorage.getItem("A-token");
  let token = "";
  if (tokenItem) {
    token = JSON.parse(localStorage.getItem("A-token"));
  }
  http.defaults.headers.common["x-auth-token"] = token;
  return token;
};

export const setToken = (token = getToken()) => {
  localStorage.setItem("A-token", JSON.stringify(token));
  http.defaults.headers.common["x-auth-token"] = token;
};

export const deleteToken = () => {
  localStorage.removeItem("A-token");
  http.defaults.headers.common["x-auth-token"] = "";
};
