import http from "../index";

export const registerUser = data => http.post("/users", data);

export const getUsers = () => http.get("/users");

export const editUser = data => http.patch("/users", data);

export const deleteUser = userId => http.delete(`/users/${userId}`);

export const uploadAvatar = avatarFile => http.post("/upload", avatarFile);

export const clearNotification = () => http.get("/users/clearNotification");

export const saveAvatar = ({ avatarURL, userId }) =>
  http.patch(`/users/${userId}/avatar`, { avatarURL });
