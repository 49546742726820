import uuid from "uuid";
import moment from "moment";

const initialState = {
  loading: false,
  loadingProjects: false,
  loadingStatusOverview: false,
  statusOverview: {},
  loadingGrids: false,
  takingScreenshot: false,
  projectsList: [],
  searchResults: [],
  analyticsAccs: [],
  analyticsProps: [],
  analyticsViews: [],
  searchLoading: false,
  error: "",
  grids: [],
  gridsToDelete: []
};

export const createProjectRequest = state => ({
  ...state,
  creatingProject: true
});

export const createProjectSuccess = (state, { payload: { project } }) => ({
  ...state,
  projectsList: [project, ...state.projectsList],
  creatingProject: false
});

export const createProjectFailure = state => ({
  ...state,
  creatingProject: false
});

export const getProjectsRequest = state => ({
  ...state,
  loadingProjects: true
});

export const getProjectsSuccess = (state, { payload }) => ({
  ...state,
  projectsList: payload,
  loadingProjects: false
});

export const getProjectsFailure = state => ({
  ...state,
  loadingProjects: false
});

export const getProjectGridsRequest = state => ({
  ...state,
  loadingGrids: true
});

export const getProjectGridsSuccess = (state, { payload }) => ({
  ...state,
  grids: payload,
  loadingGrids: false
});

export const getProjectGridsFailure = state => ({
  ...state,
  loadingGrids: false
});

export const saveGridsRequest = state => ({
  ...state,
  loading: true
});

export const saveGridsSuccess = state => ({
  ...state,
  loading: false
});

export const saveGridsFailure = state => ({
  ...state,
  loading: false
});

export const addProjectLogoRequest = state => ({
  ...state,
  logoUploading: true
});

export const addProjectLogoSuccess = state => ({
  ...state,
  logoUploading: false
});

export const addProjectLogoFailure = state => ({
  ...state,
  logoUploading: false
});

export const editProjectAnalyseTitleRequest = state => ({
  ...state,
  edititingTitle: true
});

export const editProjectAnalyseTitleSuccess = state => ({
  ...state,
  edititingTitle: false
});

export const editProjectAnalyseTitleFailure = state => ({
  ...state,
  edititingTitle: false
});

export const searchProjectsRequest = state => ({
  ...state,
  searchLoading: true,
  searchResults: []
});

export const searchProjectsSuccess = (state, { payload }) => ({
  ...state,
  searchResults: payload.projects,
  searchLoading: false
});

export const searchProjectsFailure = state => ({
  ...state,
  searchLoading: false
});

export const editProjectRequest = state => ({
  ...state,
  editingProject: true
});

export const editProjectSuccess = (
  state,
  { payload: { project: newProject } }
) => {
  const projectIndex = state.projectsList.findIndex(
    project => project._id === newProject._id
  );

  const newProjectsList = [...state.projectsList];

  newProjectsList[projectIndex] = newProject;

  return {
    ...state,
    projectsList: newProjectsList,
    editingProject: false
  };
};

export const editProjectFailure = state => ({
  ...state,
  editingProject: false
});

export const changeProjectStatusRequest = state => ({
  ...state,
  changingStatus: true
});

export const changeProjectStatusSuccess = (
  state,
  { payload: { project: newProject } }
) => {
  const projectIndex = state.projectsList.findIndex(
    project => project._id === newProject._id
  );

  const newProjectsList = [...state.projectsList];

  newProjectsList[projectIndex] = newProject;

  return {
    ...state,
    projectsList: newProjectsList,
    changingStatus: false
  };
};

export const changeProjectStatusFailure = state => ({
  ...state,
  changingStatus: false
});

export const getGoogleAuthLinkRequest = state => ({
  ...state
});

export const getGoogleAuthLinkSuccess = (state, { payload }) => {
  const { projectId, link } = payload;
  const projectIndex = state.projectsList.findIndex(
    project => project._id === projectId
  );
  const newProject = { ...state.projectsList[projectIndex] };
  const newList = [...state.projectsList];

  newProject.link = link;
  newList[projectIndex] = newProject;

  return {
    ...state,
    projectsList: newList
  };
};

export const getGoogleAuthLinkFailure = state => ({
  ...state
});

export const addGoogleUserRequest = state => ({
  ...state
});

export const addGoogleUserSuccess = state => ({
  ...state
});

export const addGoogleUserFailure = state => ({
  ...state
});

export const createNewGrid = state => {
  const newGrid = {
    size: "1/1",
    style: { gridTemplateColumns: "1fr" },
    blocks: 1,
    blocksContent: [{ content: null, type: "" }],
    id: uuid()
  };

  const newGrids = [newGrid, ...state.grids];

  return {
    ...state,
    grids: newGrids
  };
};

export const createNewGridByIndex = (state, { payload }) => {
  const index = payload + 1;
  const newGrid = {
    size: "1/1",
    style: { gridTemplateColumns: "1fr" },
    blocks: 1,
    blocksContent: [{ content: null, type: "" }],
    id: uuid()
  };

  const newGrids = [...state.grids];

  newGrids.splice(index, 0, newGrid);

  return {
    ...state,
    grids: newGrids
  };
};

export const copyGrid = (state, { payload }) => {
  const { gridId } = payload;

  const gridToCopyIndex = state.grids.findIndex(grid => grid.id === gridId);
  const gridToCopy = state.grids[gridToCopyIndex];

  const newGrid = {
    ...gridToCopy,
    id: uuid()
  };

  state.grids.splice(gridToCopyIndex + 1, 0, newGrid);

  return {
    ...state
  };
};

export const deleteGrid = (state, { payload }) => {
  const { gridId } = payload;

  const gridToDeleteIndex = state.grids.findIndex(grid => grid.id === gridId);

  state.grids.splice(gridToDeleteIndex, 1);

  return {
    ...state,
    gridsToDelete: [...state.gridsToDelete, gridId]
  };
};

export const changeGridSize = (state, { payload }) => {
  const { id } = payload;
  const gridIndex = state.grids.findIndex(grid => grid.id === id);
  const newGrids = [...state.grids];
  newGrids[gridIndex] = payload;

  return {
    ...state,
    grids: newGrids
  };
};

export const saveGridContent = (state, { payload }) => {
  const { gridId, blockIndex, blockContent, type } = payload;
  const gridIndex = state.grids.findIndex(grid => grid.id === gridId);
  const newGrids = [...state.grids];
  const newBlocksContent = [...state.grids[gridIndex].blocksContent];

  newBlocksContent[blockIndex] = { type, content: blockContent };
  newGrids[gridIndex] = {
    ...state.grids[gridIndex],
    blocksContent: newBlocksContent
  };

  return {
    ...state,
    grids: newGrids
  };
};

export const clearGrids = state => ({
  ...state,
  grids: [],
  gridsToDelete: []
});

export const getAnalyticsAccsRequest = state => ({
  ...state,
  analyticsAccs: [],
  analyticsProps: [],
  analyticsViews: []
});

export const getAnalyticsAccsSuccess = (state, { payload }) => ({
  ...state,
  analyticsAccs: payload
});

export const getAnalyticsAccsFailure = state => ({
  ...state
});

export const getAnalyticsPropsRequest = state => ({
  ...state,
  analyticsProps: [],
  analyticsViews: []
});

export const getAnalyticsPropsSuccess = (state, { payload }) => ({
  ...state,
  analyticsProps: payload
});

export const getAnalyticsPropsFailure = state => ({
  ...state
});

export const getAnalyticsViewsRequest = state => ({
  ...state,
  analyticsViews: []
});

export const getAnalyticsViewsSuccess = (state, { payload }) => ({
  ...state,
  analyticsViews: payload
});

export const getAnalyticsViewsFailure = state => ({
  ...state
});

export const saveProjectViewIdRequest = state => ({
  ...state
});

export const saveGridImageContentRequest = state => ({
  ...state
});

export const saveGridImageContentSuccess = state => ({
  ...state
});

export const saveGridImageContentFailure = state => ({
  ...state
});

export const saveGridGAContentRequest = state => ({
  ...state
});

export const saveGridGAContentSuccess = state => ({
  ...state
});

export const saveGridGAContentFailure = state => ({
  ...state
});

export const saveGridPageviewsGAReportRequest = state => ({
  ...state
});

export const saveGridPageviewsGAReportSuccess = state => ({
  ...state
});

export const saveGridPageviewsGAReportFailure = state => ({
  ...state
});

export const saveGridResolutionGAReportRequest = state => ({
  ...state
});

export const saveGridResolutionGAReportSuccess = state => ({
  ...state
});

export const saveGridResolutionGAReportFailure = state => ({
  ...state
});

export const saveProjectViewIdSuccess = (state, { payload }) => {
  const { projectId, viewId } = payload;
  const projectIndex = state.projectsList.findIndex(
    project => project._id === projectId
  );
  const newProject = { ...state.projectsList[projectIndex] };
  const newList = [...state.projectsList];

  newProject.viewId = viewId;
  newList[projectIndex] = newProject;

  return {
    ...state,
    projectsList: newList
  };
};

export const saveProjectViewIdFailure = state => ({
  ...state
});

export const getStatusOverviewRequest = state => ({
  ...state,
  loadingStatusOverview: true
});

export const getStatusOverviewSuccess = (state, { payload }) => ({
  ...state,
  loadingStatusOverview: false,
  statusOverview: payload
});

export const getStatusOverviewFailure = state => ({
  ...state,
  loadingStatusOverview: false
});

export const assignExpertRequest = state => ({
  ...state
});

export const assignExpertSuccess = (state, { payload }) => {
  const { projectId, expertId } = payload;
  const projectIndex = state.projectsList.findIndex(
    project => project._id === projectId
  );
  const newProjectsList = [...state.projectsList];

  newProjectsList[projectIndex].assignedTo = expertId;

  return {
    ...state,
    projectsList: [...newProjectsList]
  };
};

export const assignExpertFailure = state => ({
  ...state
});

export const takeScreenshotRequest = state => ({
  ...state,
  takingScreenshot: true
});

export const takeScreenshotSuccess = state => {
  return {
    ...state,
    takingScreenshot: false
  };
};

export const takeScreenshotFailure = state => ({
  ...state,
  takingScreenshot: false
});

export const addMarkerToGridItem = (state, { payload }) => {
  const { gridId, blockIndex } = payload;

  const gridIndex = state.grids.findIndex(grid => grid.id === gridId);
  const newGrids = [...state.grids];
  const newBlocksContent = [...state.grids[gridIndex].blocksContent];

  newBlocksContent[blockIndex] = {
    ...newBlocksContent[blockIndex],
    content: {
      ...newBlocksContent[blockIndex].content,
      markers: [
        ...newBlocksContent[blockIndex].content.markers,
        {
          posX: "20%",
          posY: "20%",
          id: uuid()
        }
      ]
    }
  };
  newGrids[gridIndex] = {
    ...state.grids[gridIndex],
    blocksContent: newBlocksContent
  };

  return {
    ...state,
    grids: newGrids
  };
};
export const changeMarker = (state, { payload }) => {
  const { gridId, blockIndex, marker } = payload;

  const { id: markerId } = marker;

  const gridIndex = state.grids.findIndex(grid => grid.id === gridId);
  const newGrids = [...state.grids];
  const newBlocksContent = [...state.grids[gridIndex].blocksContent];
  const newMarkers = [
    ...state.grids[gridIndex].blocksContent[blockIndex].content.markers
  ];

  const markerIndex = newMarkers.findIndex(mark => mark.id === markerId);
  newMarkers[markerIndex] = { ...marker };

  newBlocksContent[blockIndex] = {
    ...newBlocksContent[blockIndex],
    content: {
      ...newBlocksContent[blockIndex].content,
      markers: [...newMarkers]
    }
  };

  newGrids[gridIndex] = {
    ...state.grids[gridIndex],
    blocksContent: newBlocksContent
  };

  return {
    ...state,
    grids: newGrids
  };
};

export default initialState;
