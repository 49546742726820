import styled, { keyframes } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  height: 60px;
  background: #f2f6fc;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  padding: 0 30px;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionButton = styled.button`
  background: transparent;
  outline: none;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 30px;
  &:last-child {
    margin-right: 0px;
  }
  & img {
    height: 100%;
  }
  & i {
    font-size: 28px;
    color: #515974;
    transition: 0.2s;
    &:hover {
      color: #222631;
    }
  }
`;

const blink = keyframes`
  0% {
    color: #515974;
  }

  50% {
    color: #ffd700;
  }

  100% {
    color: #515974;
  }
`;

export const Bell = styled.i`
  &.blinks {
    animation: ${blink} 2s linear infinite;
  }
`;
