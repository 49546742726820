import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { actions as projectsActions } from "redux/projects";

import * as S from "./styled";

function AnalyseInfo() {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const {
    grids,
    projectsList,
    gridsToDelete,
    profile: { role }
  } = useSelector(({ projects, auth }) => ({
    grids: projects.grids,
    projectsList: projects.projectsList,
    gridsToDelete: projects.gridsToDelete,
    profile: auth.profile
  }));

  const currentProject = projectsList.find(
    project => project._id === projectId
  );
  const { logo, analyseTitle } = currentProject;

  const [title, setTitle] = useState(analyseTitle);

  const handleSave = () => {
    dispatch(
      projectsActions.saveGridsRequest({ grids, projectId, gridsToDelete })
    );
    dispatch(
      projectsActions.editProjectAnalyseTitleRequest({
        analyseTitle: title,
        projectId
      })
    );
  };

  const handleAddLogo = e => {
    const [file] = e.target.files;
    const formData = new FormData();

    formData.append("projectLogo", file);

    dispatch(projectsActions.addProjectLogoRequest({ formData, projectId }));
  };

  const handleChange = e => {
    setTitle(e.target.value);
  };

  const renderPDFButton = () => {
    if (logo) {
      return (
        <S.PDFLink href={`/api/pdf/projectReport/${projectId}`} target="_blank">
          <S.AnalyseActionButton disabled={!logo}>PDF</S.AnalyseActionButton>
        </S.PDFLink>
      );
    } else {
      return (
        <S.AnalyseActionButton disabled={!logo}>PDF</S.AnalyseActionButton>
      );
    }
  };

  return (
    <S.AnylyseInfoBlock logo={logo}>
      {logo && (
        <S.ProjectLogoBlock>
          <S.ProjectLogo>
            <img src={logo} alt="Project Avatar" />
          </S.ProjectLogo>
        </S.ProjectLogoBlock>
      )}
      <S.AnalyseInfo>
        <S.AnalyseTitle>
          <S.TitleEdit>
            <S.TitleLabel>Title</S.TitleLabel>
            <S.EditButton>edit</S.EditButton>
          </S.TitleEdit>
          <S.Title defaultValue={analyseTitle} onChange={handleChange} />
        </S.AnalyseTitle>
        <S.AnalyseActions>
          {role !== "user" && (
            <S.AnalyseActionButton onClick={handleSave}>
              Save
            </S.AnalyseActionButton>
          )}
          {renderPDFButton()}
          <S.LogoUploadWrapper>
            <input
              type="file"
              name="projectLogo"
              id="project-logo-upload"
              onChange={handleAddLogo}
            />
            <S.AnalyseActionButton className={logo ? "logo-uploaded" : null}>
              {logo ? "Logo saved" : "Upload logo"}
            </S.AnalyseActionButton>
          </S.LogoUploadWrapper>
        </S.AnalyseActions>
      </S.AnalyseInfo>
    </S.AnylyseInfoBlock>
  );
}

export default AnalyseInfo;
