import React from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";

import { actions as authActions } from "redux/users";

import * as S from "./styled";

function RegistrationForm({ history }) {
  const dispatch = useDispatch();

  const handleValidate = values => {
    let errors = {};
    const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { firstName, lastName, email, password, confirmPassword } = values;

    if (!firstName) {
      errors.firstName = "First name is required";
    }

    if (!lastName) {
      errors.lastName = "Last name is required";
    }

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!emailRegEx.test(email)) {
      errors.email = "Invalid email address";
    }

    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 6) {
      errors.password = "Password should contain at least 6 characters";
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Confirmation is required";
    } else if (confirmPassword !== password) {
      errors.confirmPassword = "Passwords do not match";
    }

    return errors;
  };

  const handleSubmit = values => {
    dispatch(
      authActions.registrationRequest({
        values,
        cb: () => history.push("/login")
      })
    );
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      validate={handleValidate}
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: ""
      }}
    >
      <S.Form>
        <S.FieldTitle>First name</S.FieldTitle>
        <S.Field
          name="firstName"
          type="string"
          placeholder="Enter your First Name"
        />
        <S.ErrMessage component="div" name="firstName" />
        <S.FieldTitle>Last name</S.FieldTitle>
        <S.Field
          name="lastName"
          type="string"
          placeholder="Enter your Last Name"
        />
        <S.ErrMessage component="div" name="lastName" />
        <S.FieldTitle>Email</S.FieldTitle>
        <S.Field name="email" type="string" placeholder="Enter the Email" />
        <S.ErrMessage component="div" name="email" />
        <S.FieldTitle>Password</S.FieldTitle>
        <S.Field
          name="password"
          type="password"
          placeholder="At least 6 characters long"
        />
        <S.ErrMessage component="div" name="password" />
        <S.FieldTitle>Confirm your password</S.FieldTitle>
        <S.Field
          name="confirmPassword"
          type="password"
          placeholder="Enter your password again"
        />
        <S.ErrMessage component="div" name="confirmPassword" />
        <S.SubmitButton type="submit">SUBMIT</S.SubmitButton>
      </S.Form>
    </Formik>
  );
}

export default RegistrationForm;
