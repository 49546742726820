import React from "react";
import ReactModal from "react-modal";

import * as S from "./styled";

const style = {
  overlay: {
    background: "rgba(0,0,0,0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000
  },
  content: {
    maxWidth: "800px",
    minWidth: "320px",
    position: "static",
    height: "auto",
    background: "#f2f6fc",
    color: "#515974",
    borderRadius: "6px",
    border: "none"
  }
};

ReactModal.setAppElement("#root");

function Confirm({ mainTitle, subTitle, onOk, onNo, ...props }) {
  return (
    <ReactModal style={style} {...props}>
      <S.MainTitle>{mainTitle}</S.MainTitle>
      <S.SubTitle>{subTitle}</S.SubTitle>
      <S.ActionButtons>
        <S.YesButton onClick={onOk}>Yes</S.YesButton>
        <S.NoButton onClick={onNo}>No</S.NoButton>
      </S.ActionButtons>
    </ReactModal>
  );
}

export default Confirm;
