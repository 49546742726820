import { toast } from "react-toastify";

import { call, put, takeLatest } from "redux-saga/effects";

import * as httpProjects from "http/projects";

import { actions as types } from "./index";

function* onCreateProject({ payload }) {
  const { values, cb } = payload;

  try {
    const result = yield call(httpProjects.createProject, values);
    const project = result.data;
    toast.success("Project successfully created");
    cb();
    yield put(types.createProjectSuccess({ project }));
  } catch (error) {
    console.log(error);
    yield put(types.createProjectFailure());
  }
}

function* onGetProjects() {
  try {
    const result = yield call(httpProjects.getProjects);
    const projects = result.data;

    yield put(types.getProjectsSuccess(projects));
  } catch (error) {
    console.log(error);
    yield put(types.getProjectsFailure());
  }
}

function* onGetProjectGrids({ payload }) {
  const { projectId } = payload;

  try {
    const result = yield call(httpProjects.getProjectGrids, projectId);

    yield put(types.getProjectGridsSuccess(result.data));
  } catch (error) {
    console.log(error);
    yield put(types.getProjectGridsFailure());
  }
}

function* onSaveGrids({ payload }) {
  const { grids, projectId, gridsToDelete } = payload;

  try {
    yield call(httpProjects.saveGrids, grids, projectId, gridsToDelete);

    toast.success("Successfully saved");
    yield put(types.saveGridsSuccess());
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong, try again");

    yield put(types.saveGridsFailure());
  }
}

function* onAddProjectLogo({ payload }) {
  const { formData, projectId } = payload;

  try {
    const uploadResult = yield call(httpProjects.uploadProjectLogo, formData);

    const { fileURL } = uploadResult.data;

    yield call(httpProjects.addProjectLogo, fileURL, projectId);

    toast.success("Logo successfully added");
    yield put(types.getProjectsRequest());
    yield put(types.addProjectLogoSuccess());
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong, try again");
    yield put(types.addProjectLogoFailure());
  }
}

function* onEditProjectAnalyseTitle({ payload }) {
  const { analyseTitle, projectId } = payload;

  try {
    yield call(httpProjects.editProjectAnalyseTitle, analyseTitle, projectId);

    yield put(types.getProjectsRequest());
    yield put(types.editProjectAnalyseTitleSuccess());
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong, try again");
    yield put(types.editProjectAnalyseTitleFailure());
  }
}

function* onSearchProjects({ payload }) {
  const { queryString } = payload;

  try {
    const res = yield call(httpProjects.searchProjects, queryString);
    const projects = res.data;
    yield put(types.searchProjectsSuccess({ projects }));
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong, try again");
    yield put(types.searchProjectsFailure());
  }
}

function* onEditProject({ payload }) {
  const { values, projectId, cb } = payload;

  try {
    const res = yield call(httpProjects.editProject, projectId, values);
    cb();
    toast.success("Project successfully saved");
    yield put(types.editProjectSuccess(res.data));
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong, try again");
    yield put(types.editProjectFailure());
  }
}

function* onChangeProjectStatus({ payload }) {
  const { newStatus, projectId } = payload;

  try {
    const res = yield call(
      httpProjects.changeProjectStatus,
      projectId,
      newStatus
    );
    toast.success("Status changed");
    yield put(types.changeProjectStatusSuccess(res.data));
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong, try again");
    yield put(types.changeProjectStatusFailure());
  }
}

function* onGetGoogleAuthLink({ payload }) {
  const { projectId } = payload;
  try {
    const res = yield call(httpProjects.getGoogleAuthLink, projectId);
    yield put(types.getGoogleAuthLinkSuccess({ projectId, link: res.data }));
  } catch (error) {
    console.log(error);
    toast.error("Cannot get google auth link");
    yield put(types.getGoogleAuthLinkFailure());
  }
}

function* onAddGoogleUser({ payload }) {
  const { projectId, code, history } = payload;
  try {
    const res = yield call(httpProjects.addGoogleUser, projectId, code);
    history.push(`/app/projects/${projectId}`);
    yield put(types.addGoogleUserSuccess({ projectId, link: res.data }));
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong");
    history.push(`/app/projects/${projectId}`);
    yield put(types.addGoogleUserFailure());
  }
}

function* onGetAnalyticsAccs({ payload }) {
  const { projectId } = payload;
  try {
    const res = yield call(httpProjects.getAnalyticsAccs, projectId);
    yield put(types.getAnalyticsAccsSuccess(res.data.accounts));
  } catch (error) {
    console.log(error);
    toast.error("Google user does not have analytics accounts");
    yield put(types.getAnalyticsAccsFailure());
  }
}
function* onGetAnalyticsProps({ payload }) {
  const { projectId, accountId } = payload;
  try {
    const res = yield call(
      httpProjects.getAnalyticsProps,
      projectId,
      accountId
    );
    yield put(types.getAnalyticsPropsSuccess(res.data.webproperties));
  } catch (error) {
    console.log(error);
    toast.error("Google user does not have analytics webproperties");
    yield put(types.getAnalyticsPropsFailure());
  }
}
function* onGetAnalyticsViews({ payload }) {
  const { projectId, accountId, propId } = payload;
  try {
    const res = yield call(
      httpProjects.getAnalyticsViews,
      projectId,
      accountId,
      propId
    );
    yield put(types.getAnalyticsViewsSuccess(res.data.profiles));
  } catch (error) {
    console.log(error);
    toast.error("Google user does not have analytics views");
    yield put(types.getAnalyticsViewsFailure());
  }
}
function* onSaveProjectViewId({ payload }) {
  const { projectId, viewId, cb } = payload;
  try {
    yield call(httpProjects.editProject, projectId, { viewId });
    toast.success("View ID successfully saved");
    cb();
    yield put(types.saveProjectViewIdSuccess(payload));
  } catch (error) {
    console.log(error);
    toast.error("Failed to save view ID, please, try again");
    yield put(types.saveProjectViewIdFailure());
  }
}
function* onSaveGridImageContent({ payload }) {
  const { image, cb, ...params } = payload;
  try {
    const res = yield call(httpProjects.uploadImage, image);
    yield put(types.saveGridContent({ ...params, blockContent: res.data }));
    cb();
    yield put(types.saveGridImageContentSuccess());
  } catch (error) {
    console.log(error);
    toast.error("Failed to upload, please, try again");
    yield put(types.saveGridImageContentFailure());
  }
}
function* onSaveGridGAContent({ payload }) {
  const { queryString, projectId, cb, ...params } = payload;
  try {
    const res = yield call(httpProjects.getGAMetrics, projectId, queryString);

    yield put(types.saveGridContent({ ...params, blockContent: res.data }));
    cb();
    yield put(types.saveGridGAContentSuccess());
  } catch (error) {
    console.log(error);
    toast.error("Failed to fetch analytics data, please, try again");
    yield put(types.saveGridGAContentFailure());
  }
}
function* onSaveGridPageviewsGAReport({ payload }) {
  const { projectId, cb, ...params } = payload;
  try {
    const res = yield call(httpProjects.getPageviewsGAReport, projectId);

    yield put(types.saveGridContent({ ...params, blockContent: res.data }));

    cb();
  } catch (error) {
    console.log(error);
  }
}
function* onSaveGridResolutionGAReport({ payload }) {
  const { projectId, cb, ...params } = payload;
  try {
    const res = yield call(httpProjects.getResolutionGAReport, projectId);

    yield put(types.saveGridContent({ ...params, blockContent: res.data }));

    cb();
  } catch (error) {
    console.log(error);
  }
}
function* onGetStatusOverview() {
  try {
    const res = yield call(httpProjects.getStatusOverview);

    yield put(types.getStatusOverviewSuccess(res.data));
  } catch (error) {
    //console.log(error);
    yield put(types.getStatusOverviewFailure());
  }
}

function* onAssignExpert({ payload }) {
  const { projectId, expertId, cb } = payload;
  try {
    yield call(httpProjects.assignExpert, projectId, expertId);
    yield put(types.assignExpertSuccess({ projectId, expertId }));
    cb();
  } catch (error) {
    console.log(error);
    yield put(types.assignExpertFailure());
  }
}
function* onTakeScreenshot({ payload }) {
  const { URL, cb, ...params } = payload;
  try {
    const res = yield call(httpProjects.takeScreenshot, URL);
    const imageURL = res.data.file;

    const blockContent = {
      screenshotURL: imageURL,
      markers: []
    };
    yield put(types.takeScreenshotSuccess());

    yield put(types.saveGridContent({ ...params, blockContent }));
    cb();
  } catch (error) {
    console.log(error);
    yield put(types.takeScreenshotFailure());
  }
}

const authSagas = [
  takeLatest(types.createProjectRequest, onCreateProject),
  takeLatest(types.getProjectsRequest, onGetProjects),
  takeLatest(types.getProjectGridsRequest, onGetProjectGrids),
  takeLatest(types.saveGridsRequest, onSaveGrids),
  takeLatest(types.addProjectLogoRequest, onAddProjectLogo),
  takeLatest(types.editProjectAnalyseTitleRequest, onEditProjectAnalyseTitle),
  takeLatest(types.searchProjectsRequest, onSearchProjects),
  takeLatest(types.editProjectRequest, onEditProject),
  takeLatest(types.changeProjectStatusRequest, onChangeProjectStatus),
  takeLatest(types.getGoogleAuthLinkRequest, onGetGoogleAuthLink),
  takeLatest(types.addGoogleUserRequest, onAddGoogleUser),
  takeLatest(types.getAnalyticsAccsRequest, onGetAnalyticsAccs),
  takeLatest(types.getAnalyticsPropsRequest, onGetAnalyticsProps),
  takeLatest(types.getAnalyticsViewsRequest, onGetAnalyticsViews),
  takeLatest(types.saveProjectViewIdRequest, onSaveProjectViewId),
  takeLatest(types.saveGridImageContentRequest, onSaveGridImageContent),
  takeLatest(types.saveGridGAContentRequest, onSaveGridGAContent),
  takeLatest(
    types.saveGridPageviewsGAReportRequest,
    onSaveGridPageviewsGAReport
  ),
  takeLatest(
    types.saveGridResolutionGAReportRequest,
    onSaveGridResolutionGAReport
  ),
  takeLatest(types.getStatusOverviewRequest, onGetStatusOverview),
  takeLatest(types.assignExpertRequest, onAssignExpert),
  takeLatest(types.takeScreenshotRequest, onTakeScreenshot)
];

export default authSagas;
