import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 40px;
`;
export const LeftInfoBlock = styled.div`
  display: flex;
  align-items: center;
`;
export const AddAnalyticsLink = styled.a`
  text-decoration: none;
  border-radius: 5px;
  background-color: #1b2133;
  padding: 8px 16px;
  opacity: 0.55;
  color: #ffffff;
`;

export const SetViewButton = styled.button`
  text-decoration: none;
  border-radius: 5px;
  background-color: #1b2133;
  padding: 8px 15px;
  opacity: 0.55;
  border: none;
  outline: none;
  font-size: 16px;
  cursor: pointer;
  color: #ffffff;
  margin-left: 20px;
`;

export const Analyse = styled.span`
  font-size: 28px;
  font-weight: bold;
  color: #515974;
`;

export const ProjectNameBlock = styled.div`
  opacity: 0.55;
  border-radius: 5px;
  background-color: #1b2133;
  padding: 8px 15px;
  margin: 0 20px;
`;

export const ProjectName = styled.span`
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  color: #ffffff;
`;

export const ProjectURL = styled.div`
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
  color: #828282;
`;
