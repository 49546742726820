import { toast } from "react-toastify";

import { call, put, takeLatest } from "redux-saga/effects";

import * as httpUsers from "http/users";

import { actions as types } from "./index";
import { actions as authTypes } from "../auth/index";

function* onRegister({ payload }) {
  const { values, cb } = payload;

  try {
    const params = {
      ...values,
      email: values.email.trim()
    };
    const res = yield call(httpUsers.registerUser, params);
    const { user } = res.data;
    toast.success(`${user.email} successfully registered`);
    yield put(types.registrationSuccess({ user }));
    cb();
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong, try again");
    yield put(types.registrationFailure());
  }
}

function* onGetUsers() {
  try {
    const result = yield call(httpUsers.getUsers);

    yield put(types.getUsersSuccess(result.data));
  } catch (error) {
    console.log(error);
    yield put(types.getUsersFailure());
  }
}

function* onEditUser({ payload }) {
  const { values, cb } = payload;
  try {
    const params = {
      ...values,
      email: values.email.trim()
    };
    const res = yield call(httpUsers.editUser, params);
    const { user } = res.data;
    yield put(types.editUserSuccess({ dbUser: user }));
    toast.success(`${values.email} successfully saved`);
    yield put(authTypes.getProfileRequest());
    cb();
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong, try again");
    yield put(types.editUserFailure());
  }
}
function* onDeleteUser({ payload }) {
  const { userId } = payload;
  try {
    yield call(httpUsers.deleteUser, userId);
    yield put(types.deleteUserSuccess({ userId }));
    toast.success(`User successfully deleted`);
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong, try again");
    yield put(types.deleteUserFailure());
  }
}

function* onSaveUserAvatar({ payload }) {
  const { userId, avatarFile } = payload;

  const formData = new FormData();

  formData.append("image", avatarFile);

  try {
    const result = yield call(httpUsers.uploadAvatar, formData);
    const avatarURL = result.data.fileURL;
    yield call(httpUsers.saveAvatar, { avatarURL, userId });
    yield put(types.saveUserAvatarSuccess());
    yield put(authTypes.getProfileRequest());
  } catch (error) {
    console.log(error);
    toast.error("Failed to update avatar, try again");
    yield put(types.saveUserAvatarFailure());
  }
}

function* onClearNotification() {
  try {
    yield call(httpUsers.clearNotification);

    yield put(authTypes.clearNotification());
    yield put(types.clearNotificationSuccess());
  } catch (error) {
    console.log(error);
    yield put(types.clearNotificationFailure());
  }
}

const authSagas = [
  takeLatest(types.registrationRequest, onRegister),
  takeLatest(types.getUsersRequest, onGetUsers),
  takeLatest(types.editUserRequest, onEditUser),
  takeLatest(types.deleteUserRequest, onDeleteUser),
  takeLatest(types.saveUserAvatarRequest, onSaveUserAvatar),
  takeLatest(types.clearNotificationRequest, onClearNotification)
];

export default authSagas;
