import styled from "styled-components";

export const SpinnerWrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const Wrapper = styled.div`
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.15);
  background-color: #f2f6fc;
`;

export const Header = styled.div`
  display: flex;
  border-bottom: 2px solid #e1e7f0;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  color: #515974;
  display: block;
  padding: 12px 30px;
`;

export const AddButton = styled.button`
  background: #2695b5;
  border: none;
  outline: none;
  border-radius: 16px;
  height: 30px;
  padding: 8px 12px;
  margin-right: 15px;
  cursor: pointer;
  color: #ffffff;
`;
