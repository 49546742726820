import { handleActions, createActions } from "redux-actions";

import initialState, * as handlers from "./handlers";

export const actions = createActions({
  REGISTRATION_REQUEST: undefined,
  REGISTRATION_SUCCESS: undefined,
  REGISTRATION_FAILURE: undefined,

  GET_USERS_REQUEST: undefined,
  GET_USERS_SUCCESS: undefined,
  GET_USERS_FAILURE: undefined,

  EDIT_USER_REQUEST: undefined,
  EDIT_USER_SUCCESS: undefined,
  EDIT_USER_FAILURE: undefined,

  DELETE_USER_REQUEST: undefined,
  DELETE_USER_SUCCESS: undefined,
  DELETE_USER_FAILURE: undefined,

  SAVE_USER_AVATAR_REQUEST: undefined,
  SAVE_USER_AVATAR_SUCCESS: undefined,
  SAVE_USER_AVATAR_FAILURE: undefined,

  CLEAR_NOTIFICATION_REQUEST: undefined,
  CLEAR_NOTIFICATION_SUCCESS: undefined,
  CLEAR_NOTIFICATION_FAILURE: undefined
});

const reducer = handleActions(
  new Map([
    [actions.registrationRequest, handlers.registrationRequest],
    [actions.registrationSuccess, handlers.registrationSuccess],
    [actions.registrationFailure, handlers.registrationFailure],

    [actions.getUsersRequest, handlers.getUsersRequest],
    [actions.getUsersSuccess, handlers.getUsersSuccess],
    [actions.getUsersFailure, handlers.getUsersFailure],

    [actions.editUserRequest, handlers.editUserRequest],
    [actions.editUserSuccess, handlers.editUserSuccess],
    [actions.editUserFailure, handlers.editUserFailure],

    [actions.deleteUserRequest, handlers.deleteUserRequest],
    [actions.deleteUserSuccess, handlers.deleteUserSuccess],
    [actions.deleteUserFailure, handlers.deleteUserFailure],

    [actions.saveUserAvatarRequest, handlers.saveUserAvatarRequest],
    [actions.saveUserAvatarSuccess, handlers.saveUserAvatarSuccess],
    [actions.saveUserAvatarFailure, handlers.saveUserAvatarFailure],

    [actions.clearNotificationRequest, handlers.clearNotificationRequest],
    [actions.clearNotificationSuccess, handlers.clearNotificationSuccess],
    [actions.clearNotificationFailure, handlers.clearNotificationFailure]
  ]),
  initialState
);

export default reducer;
