import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import MenuItem from "../MenuItem";

import dashboardIcon from "assets/images/dashboardIcon.png";
import projectsIcon from "assets/images/projectsIcon.png";
import accountsIcon from "assets/images/accountsIcon.png";

import * as S from "./styled";

const menuLinks = {
  admin: [
    {
      title: "Dashboard",
      href: "/app/dashboard",
      icon: dashboardIcon
    },
    {
      title: "Projects",
      href: "/app/projects",
      icon: projectsIcon
    },
    {
      title: "Accounts",
      href: "/app/accounts",
      icon: accountsIcon
    }
  ],
  expert: [
    {
      title: "Dashboard",
      href: "/app/dashboard",
      icon: dashboardIcon
    },
    {
      title: "Projects",
      href: "/app/projects",
      icon: projectsIcon
    }
  ],
  user: [
    {
      title: "Projects",
      href: "/app/projects",
      icon: projectsIcon
    }
  ]
};

function Menu({ isOpen, location: { pathname } }) {
  const { profile } = useSelector(({ auth }) => ({
    profile: auth.profile
  }));

  return (
    <div>
      {menuLinks[profile.role].map(link => (
        <S.MenuLink to={link.href} key={link.href}>
          <MenuItem
            active={pathname === link.href}
            title={link.title}
            icon={link.icon}
            isOpen={isOpen}
          />
        </S.MenuLink>
      ))}
    </div>
  );
}

export default withRouter(Menu);
