import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  text-align: center;
`;

export const MarksList = styled.ul`
  list-style: none;
  text-align: left;
  font-size: 20px;
  color: #363840;
  padding-left: 0;
  margin-left: 20px;
  & li {
    margin: 5px 0;
  }
`;

export const MarksDescrTitle = styled.div`
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 30px;
  color: #363840;
  font-weight: bold;
  text-align: left;
  margin-left: 20px;
`;
