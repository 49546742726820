import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";

import { actions as projectsActions } from "redux/projects";

import searchIcon from "assets/images/searchIcon.png";

import * as S from "./styled";
import LoadingSpinner from "components/LoadingSpinner";

const SearchArea = () => {
  const dispatch = useDispatch();
  const [isShowResults, setIsShowResults] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { searchResults, searchLoading } = useSelector(({ projects }) => ({
    searchResults: projects.searchResults,
    searchLoading: projects.searchLoading
  }));
  let timer = null;

  const changeHandleSearch = e => {
    if (!isShowResults) {
      setIsShowResults(true);
    }
    setSearchQuery(e.target.value);
    clearTimeout(timer);
  };

  const triggerChange = () => {
    dispatch(
      projectsActions.searchProjectsRequest({ queryString: searchQuery })
    );
  };

  const handleOutClick = () => {
    setIsShowResults(false);
    setSearchQuery("");
  };

  useEffect(() => {
    if (searchQuery.length === 3) {
      triggerChange();
    } else if (searchQuery.length > 2) {
      timer = setTimeout(triggerChange, 800);
    }
  }, [searchQuery]);

  const renderSearchResContent = () => {
    if (searchQuery.length <= 2) {
      return <S.InfoMessage>Enter more symbols</S.InfoMessage>;
    }
    if (searchLoading) {
      return <LoadingSpinner />;
    }
    if (searchResults.length === 0) {
      return <S.InfoMessage>No projects found</S.InfoMessage>;
    }

    return searchResults.map(({ name, page, logo, _id }) => (
      <S.Link key={_id} to={`/app/projects/${_id}`} onClick={handleOutClick}>
        <S.ProjectItem>
          <S.ProjectInfo>
            <S.ProjectName>{name}</S.ProjectName>
            <S.ProjectPage>{page}</S.ProjectPage>
          </S.ProjectInfo>
          {logo && (
            <S.ProjectLogo>
              <img src={logo} alt="Project Logo" />
            </S.ProjectLogo>
          )}
        </S.ProjectItem>
      </S.Link>
    ));
  };

  return (
    <S.Wrapper>
      <OutsideClickHandler onOutsideClick={handleOutClick}>
        <S.SearchArea>
          <S.ActionButton>
            <img src={searchIcon} alt="Search" />
          </S.ActionButton>
          <S.SearchInput
            placeholder="Project Search"
            onChange={changeHandleSearch}
            value={searchQuery}
          />
          {isShowResults && (
            <S.ResultArea>{renderSearchResContent()}</S.ResultArea>
          )}
        </S.SearchArea>
      </OutsideClickHandler>
    </S.Wrapper>
  );
};

export default SearchArea;
