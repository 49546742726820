import styled from 'styled-components';

export const Container = styled.div`
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
export const Title = styled.span`
    font-size: 24px;
    font-weight: 400;
    color: #033986;
`;