import React from "react";
import { useSelector } from "react-redux";

import * as S from "./styled";

function Table({ onOpenModal, experts }) {
  const { projectsList } = useSelector(({ projects }) => ({
    projectsList: projects.projectsList
  }));

  const getExpert = expertId => {
    if (!expertId) {
      return "-";
    }

    const expert = experts.find(exp => exp._id === expertId);

    if (expert) {
      return `${expert.firstName} ${expert.lastName}`;
    }

    return "-";
  };

  const renderProjects = () => {
    return projectsList.map(project => {
      return (
        <S.TableRow key={project._id}>
          <S.NameCell>
            <S.Link to={`projects/${project._id}`}>{project.name}</S.Link>
          </S.NameCell>
          <S.PageCell>{project.page}</S.PageCell>
          <S.PageCell>{getExpert(project.assignedTo)}</S.PageCell>
          <td>
            <S.EditBtn onClick={() => onOpenModal(project._id)}>
              Assign
            </S.EditBtn>
          </td>
        </S.TableRow>
      );
    });
  };

  return (
    <S.Table>
      <thead>
        <S.TableRow>
          <S.HeadCell>Project Name</S.HeadCell>
          <S.HeadCell>Page</S.HeadCell>
          <S.HeadCell>Expert</S.HeadCell>
          <S.HeadCell />
        </S.TableRow>
      </thead>
      <tbody>{renderProjects()}</tbody>
    </S.Table>
  );
}

export default Table;
