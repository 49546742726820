import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LoadingSpinner from "components/LoadingSpinner";

import { actions as usersActions } from "redux/users";

import Table from "./components/Table";
import EditUserModal from "./components/EditUserModal";
import CreateUserModal from "./components/CreateUserModal";

import * as S from "./styled";

function AccountListPage() {
  const dispatch = useDispatch();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [userIdToEdit, setUserIdToEdit] = useState("");
  const { loadingUsers } = useSelector(({ users }) => ({
    loadingUsers: users.loadingUsers
  }));

  const handleOpenEditModal = userId => {
    setShowEditModal(true);
    setUserIdToEdit(userId);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleOpenAddModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const handleDeleteUser = userId => () => {
    dispatch(usersActions.deleteUserRequest({ userId }));
  };

  if (loadingUsers) {
    return (
      <S.SpinnerWrapper>
        <LoadingSpinner />
      </S.SpinnerWrapper>
    );
  }

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>Accounts</S.Title>
        <S.AddButton onClick={handleOpenAddModal}>Create user</S.AddButton>
      </S.Header>
      <Table
        onOpenModal={handleOpenEditModal}
        handleDeleteUser={handleDeleteUser}
      />
      <EditUserModal
        userIdToEdit={userIdToEdit}
        showModal={showEditModal}
        handleCloseModal={handleCloseEditModal}
      />
      <CreateUserModal
        showModal={showAddModal}
        handleCloseModal={handleCloseAddModal}
      />
    </S.Wrapper>
  );
}

export default AccountListPage;
