const initialState = {
  registrationInProcess: false,
  error: "",
  usersList: []
};

export const registrationRequest = state => ({
  ...state,
  registrationInProcess: true,
  error: ""
});

export const registrationSuccess = (state, { payload: { user } }) => ({
  ...state,
  usersList: [user, ...state.usersList],
  registrationInProcess: false
});

export const registrationFailure = state => ({
  ...state,
  registrationInProcess: false,
  error: "Registration failed, try again"
});

export const getUsersRequest = state => ({
  ...state,
  loadingUsers: true,
  error: ""
});

export const getUsersSuccess = (state, { payload }) => ({
  ...state,
  loadingUsers: false,
  usersList: payload
});

export const getUsersFailure = state => ({
  ...state,
  loadingUsers: false,
  error: "Loading users failed, try again"
});

export const editUserRequest = state => ({
  ...state,
  editingUser: true,
  error: ""
});

export const editUserSuccess = (state, { payload: { dbUser } }) => {
  const userIndex = state.usersList.findIndex(user => user._id === dbUser._id);

  const newUsersList = [...state.usersList];

  newUsersList[userIndex] = dbUser;

  return {
    ...state,
    usersList: newUsersList,
    editingUser: false
  };
};

export const editUserFailure = state => ({
  ...state,
  editingUser: false,
  error: "Editing user failed, try again"
});

export const deleteUserRequest = state => ({
  ...state,
  deletingUser: true,
  error: ""
});

export const deleteUserSuccess = (state, { payload: { userId } }) => {
  const userIndex = state.usersList.findIndex(user => user._id === userId);

  const newUsersList = [...state.usersList];

  newUsersList.splice(userIndex, 1);

  return {
    ...state,
    usersList: newUsersList,
    deletingUser: false
  };
};

export const deleteUserFailure = state => ({
  ...state,
  deletingUser: false,
  error: "Deleting user failed, try again"
});

export const saveUserAvatarRequest = state => ({
  ...state
});

export const saveUserAvatarSuccess = state => ({
  ...state
});

export const saveUserAvatarFailure = state => ({
  ...state
});

export const clearNotificationRequest = state => ({
  ...state
});

export const clearNotificationSuccess = state => ({
  ...state
});

export const clearNotificationFailure = state => ({
  ...state
});

export default initialState;
