import React from "react";
import { Editor } from "react-draft-wysiwyg";

const WYSIWYG = ({ onChange, defaultValue }) => {
  return (
    <Editor
      onContentStateChange={onChange}
      defaultContentState={defaultValue}
      editorStyle={{
        maxHeight: "500px",
        minHeight: "500px",
        background: "#FFFFFF",
        padding: "10px"
      }}
    />
  );
};

export default WYSIWYG;
