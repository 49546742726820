import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { actions as projectsActions } from "redux/projects";

import Header from "./components/Header";
import AnalyseInfo from "./components/AnalyseInfo";

import * as S from "./styled";
import GridManager from "./components/GridManager";

function ProjectPage() {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const { projectsList } = useSelector(({ projects }) => ({
    projectsList: projects.projectsList
  }));

  const currentProject = projectsList.find(
    project => project._id === projectId
  );

  useEffect(() => {
    dispatch(projectsActions.getProjectsRequest());

    return () => {
      dispatch(projectsActions.clearGrids());
    };
  }, [dispatch]);

  if (!projectsList.length || !currentProject) {
    return null;
  }

  return (
    <S.Wrapper>
      <Header currentProject={currentProject} />
      <AnalyseInfo />
      <GridManager />
    </S.Wrapper>
  );
}

export default ProjectPage;
