import styled from "styled-components";

export const ModalTitle = styled.div`
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  color: #515974;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 14px 0px;
  border-bottom: 2px solid #e1e7f0;
`;

export const ExpertsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ExpertItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  padding: 7px;
  border-bottom: 2px solid #e1e7f0;
  color: #515974;
  &:hover {
    background: #dddddd;
  }

  & div {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    overflow: hidden;
    & img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const CloseModalBtn = styled.button`
  background: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  color: #515974;
  cursor: pointer;
`;
