import React from "react";
import { useSelector } from "react-redux";

import * as S from "./styled";

function Table({ onOpenModal, handleDeleteUser }) {
  const { usersList } = useSelector(({ users }) => ({
    usersList: users.usersList
  }));

  const renderUsers = () => {
    return usersList.map(user => {
      return (
        <S.TableRow key={user._id}>
          <S.EmailCell>{user.email}</S.EmailCell>
          <S.InfoCell>{user.firstName}</S.InfoCell>
          <S.InfoCell>{user.lastName}</S.InfoCell>
          <S.InfoCell>{user.role}</S.InfoCell>
          <td>
            <S.ActionButton
              onClick={() => onOpenModal(user._id)}
              className="editBtn"
            >
              Edit
            </S.ActionButton>
            <S.ActionButton
              onClick={handleDeleteUser(user._id)}
              className="deleteBtn"
            >
              Delete
            </S.ActionButton>
          </td>
        </S.TableRow>
      );
    });
  };

  return (
    <S.Table>
      <thead>
        <S.TableRow>
          <S.HeadCell>Email</S.HeadCell>
          <S.HeadCell>First Name</S.HeadCell>
          <S.HeadCell>Last Name</S.HeadCell>
          <S.HeadCell>Role</S.HeadCell>
          <S.HeadCell>Actions</S.HeadCell>
        </S.TableRow>
      </thead>
      <tbody>{renderUsers()}</tbody>
    </S.Table>
  );
}

export default Table;
