import React, { useState } from "react";

import * as S from "./styled";

const GAMetrics = ({ onClose, onSave }) => {
  const [selectedMetrics, setSelectedMetrics] = useState([]);

  const checkIsActive = value => !!selectedMetrics.find(elem => elem === value);

  const handleClick = value => () => {
    const index = selectedMetrics.findIndex(elem => elem === value);
    const newMetrics = [...selectedMetrics];
    if (index + 1) {
      newMetrics.splice(index, 1);
    } else {
      newMetrics.push(value);
    }
    setSelectedMetrics(newMetrics);
  };

  const handleSave = () => {
    onSave(selectedMetrics, "GA", onClose);
  };

  return (
    <div>
      <S.MetricsWrapper>
        <S.Metrica
          isActive={checkIsActive("ga:uniquePageviews")}
          onClick={handleClick("ga:uniquePageviews")}
        >
          Unique Page Views
        </S.Metrica>
        <S.Metrica
          isActive={checkIsActive("ga:pageviews")}
          onClick={handleClick("ga:pageviews")}
        >
          Page Views
        </S.Metrica>
        <S.Metrica
          isActive={checkIsActive("ga:timeOnPage")}
          onClick={handleClick("ga:timeOnPage")}
        >
          Time on Page
        </S.Metrica>
        <S.Metrica
          isActive={checkIsActive("ga:users")}
          onClick={handleClick("ga:users")}
        >
          Users
        </S.Metrica>
        <S.Metrica
          isActive={checkIsActive("ga:pageLoadTime")}
          onClick={handleClick("ga:pageLoadTime")}
        >
          Page Load Time
        </S.Metrica>
      </S.MetricsWrapper>
      <S.ActionButtonContainer>
        <S.ActionButton onClick={onClose}>Cancel</S.ActionButton>
        <S.ActionButton onClick={handleSave} disabled={!selectedMetrics.length}>
          Save
        </S.ActionButton>
      </S.ActionButtonContainer>
    </div>
  );
};

export default GAMetrics;
