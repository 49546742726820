import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { actions as projectsActions } from "redux/projects";

import * as S from "./styled";

function Table({ onOpenModal, experts }) {
  const dispatch = useDispatch();
  const {
    projectsList,
    profile: { role }
  } = useSelector(({ projects, auth }) => ({
    projectsList: projects.projectsList,
    profile: auth.profile
  }));

  let filteredProjects = [];

  const urlParams = new URLSearchParams(window.location.search);
  const projectStatus = urlParams.get("projectStatus");
  if (projectStatus) {
    filteredProjects = projectsList.filter(
      proj => proj.status === projectStatus
    );
  } else {
    filteredProjects = projectsList;
  }

  const handleChange = projectId => e => {
    dispatch(
      projectsActions.changeProjectStatusRequest({
        projectId,
        newStatus: e.target.value
      })
    );
  };

  const renderProjects = () => {
    const getColor = project => {
      switch (project.status) {
        case "open":
          return "#2695b5";
        case "checking":
          return "#f45b63";
        case "complete":
          return "#5fb526";
        case "inProcess":
          return "#ea9c14";
        default:
          return "#2695b5";
      }
    };

    const getExpert = expertId => {
      const expert = experts.find(exp => exp._id === expertId);

      if (expert && expert.avatarURL) {
        return (
          <S.Avatar>
            <img src={expert.avatarURL} />
          </S.Avatar>
        );
      } else {
        return <span>-</span>;
      }
    };

    return filteredProjects.map(project => {
      return (
        <S.TableRow key={project._id}>
          <S.NameCell>
            <S.Link to={`/app/projects/${project._id}`}>{project.name}</S.Link>
          </S.NameCell>

          <S.PageCell>{project.page}</S.PageCell>
          <td>
            <S.CRI>{project.CRI || "-"}</S.CRI>
          </td>
          <S.DateCell>{moment(project.updatedAt).format("L")}</S.DateCell>
          <td>
            <S.EditBtn onClick={() => onOpenModal(project._id)}>Edit</S.EditBtn>
            <S.StatusSelect
              color={getColor(project)}
              defaultValue={project.status}
              onChange={handleChange(project._id)}
              disabled={role === "user"}
            >
              <S.Option color="#2695b5" value="open">
                Open
              </S.Option>
              <S.Option color="#f45b63" value="checking">
                Checking
              </S.Option>
              <S.Option color="#5fb526" value="complete">
                Complete
              </S.Option>
              <S.Option color="#ea9c14" value="inProcess">
                In process
              </S.Option>
            </S.StatusSelect>
            {project.status === "complete" && (
              <S.PDFLink
                href={`/api/pdf/projectReport/${project._id}`}
                target="_blank"
              >
                <S.PDFButton>PDF</S.PDFButton>
              </S.PDFLink>
            )}
          </td>
          <S.ExpertCell>{getExpert(project.assignedTo)}</S.ExpertCell>
        </S.TableRow>
      );
    });
  };

  return (
    <S.Table>
      <thead>
        <S.TableRow>
          <S.HeadCell>Project Name</S.HeadCell>
          <S.HeadCell>Page</S.HeadCell>
          <S.HeadCell>CRI</S.HeadCell>
          <S.HeadCell>Updated</S.HeadCell>
          <S.HeadCell>Actions</S.HeadCell>
          <S.HeadCell>Expert</S.HeadCell>
        </S.TableRow>
      </thead>
      <tbody>{renderProjects()}</tbody>
    </S.Table>
  );
}

export default Table;
