import React, { useState } from "react";
import { useSelector } from "react-redux";
import Draggable from "react-draggable";

import * as S from "./styled";

function Marker({ marker, onChangeMarker, index, role }) {
  const { profile, usersList } = useSelector(({ auth, users }) => ({
    profile: auth.profile,
    usersList: users.usersList
  }));

  const [comment, setComment] = useState(marker.comment);
  const [dragging, setDragging] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dialogStyles, setDialogStyled] = useState({
    position: "absolute",
    top: marker.posY,
    left: `calc(${marker.posX} + 40px)`,
    zIndex: "1000"
  });
  const handleClick = (e, node) => {
    e.preventDefault();
    e.stopPropagation();
    if (isOpen) {
      return setIsOpen(false);
    }

    if (node.offsetLeft > node.offsetParent.offsetWidth - 340) {
      if (node.offsetTop > node.offsetParent.offsetHeight - 400) {
        setDialogStyled({
          position: "absolute",
          top: `calc(${marker.posY} - 360px)`,
          left: `calc(${marker.posX} - 380px)`,
          zIndex: "1000"
        });
      } else {
        setDialogStyled({
          position: "absolute",
          top: marker.posY,
          left: `calc(${marker.posX} - 380px)`,
          zIndex: "1000"
        });
      }
    } else {
      if (node.offsetTop > node.offsetParent.offsetHeight - 400) {
        setDialogStyled({
          position: "absolute",
          top: `calc(${marker.posY} - 360px)`,
          left: `calc(${marker.posX} + 40px)`,
          zIndex: "1000"
        });
      } else {
        setDialogStyled({
          position: "absolute",
          top: marker.posY,
          left: `calc(${marker.posX} + 40px)`,
          zIndex: "1000"
        });
      }
    }

    setIsOpen(true);
  };

  const handleStop = (e, drgData) => {
    const { node, lastX, lastY } = drgData;
    if (dragging) {
      const newLeft = (node.offsetLeft + lastX) / node.offsetParent.offsetWidth;
      const newTop = (node.offsetTop + lastY) / node.offsetParent.offsetHeight;

      onChangeMarker({
        ...marker,
        posX: `${newLeft * 100}%`,
        posY: `${newTop * 100}%`
      });
      setDragging(false);
    } else {
      handleClick(e, node);
    }
  };

  const handleDrag = () => {
    setIsOpen(false);
    setDragging(true);
  };

  const handleChange = e => {
    setComment(e.target.value);
    onChangeMarker({
      ...marker,
      comment: e.target.value,
      author: profile._id
    });
  };

  const getAuthorInfo = () => {
    if (marker.author) {
      const author = usersList.find(user => user._id === marker.author);
      return (
        <S.ExpertInfo>
          <S.Avatar className="avatar" src={author.avatarURL} />
          <S.Name>{`${author.firstName} ${author.lastName}`}</S.Name>
        </S.ExpertInfo>
      );
    }
  };

  const handleUserClick = e => {
    e.stopPropagation();
    if (role === "user") {
      handleClick(e, e.target.parentNode);
    }
  };

  return (
    <>
      <Draggable
        bounds="parent"
        handle=".marker"
        onStop={handleStop}
        position={{ x: 0, y: 0 }}
        onDrag={handleDrag}
        disabled={role === "user"}
      >
        <S.Wrapper
          style={{
            position: "absolute",
            top: marker.posY,
            left: marker.posX,
            zIndex: isOpen ? "1000" : "1"
          }}
        >
          <S.Marker className="marker" onClick={handleUserClick}>
            {index + 1}
          </S.Marker>
        </S.Wrapper>
      </Draggable>
      {isOpen && !dragging && (
        <S.CommentWrapper
          onClick={e => e.stopPropagation()}
          style={dialogStyles}
        >
          {getAuthorInfo()}
          <S.TextArea
            value={comment}
            onChange={handleChange}
            disabled={role === "user"}
          />
        </S.CommentWrapper>
      )}
    </>
  );
}

export default Marker;
