import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";

import { actions as projectsActions } from "redux/projects";

import Modal from "components/Modal";

import * as S from "./styled";

const EditUserModal = ({ projectIdToEdit, handleCloseModal, showModal }) => {
  const dispatch = useDispatch();
  const {
    projectsList,
    profile: { role }
  } = useSelector(({ projects, auth }) => ({
    projectsList: projects.projectsList,
    profile: auth.profile
  }));
  let projectToEdit = {};

  if (projectIdToEdit) {
    projectToEdit = projectsList.find(
      project => project._id === projectIdToEdit
    );
  }

  const handleValidate = values => {
    let errors = {};

    const { name, page } = values;

    if (!name) {
      errors.name = "Name is required";
    }

    if (!page) {
      errors.page = "Page is required";
    }

    return errors;
  };

  const handleSubmit = values => {
    dispatch(
      projectsActions.editProjectRequest({
        projectId: projectToEdit._id,
        values: { ...values, _id: projectToEdit._id },
        cb: handleCloseModal
      })
    );
  };

  return (
    <Modal isOpen={showModal} onRequestClose={handleCloseModal}>
      <S.ModalTitle>
        <span>Edit Project</span>
        <S.CloseModalBtn onClick={handleCloseModal}>x</S.CloseModalBtn>
      </S.ModalTitle>
      <Formik
        onSubmit={handleSubmit}
        validate={handleValidate}
        initialValues={{
          name: projectToEdit.name || "",
          page: projectToEdit.page || "",
          CRI: projectToEdit.CRI || ""
        }}
      >
        <S.Form>
          <S.FieldTitle>Name</S.FieldTitle>
          <S.Field name="name" type="string" placeholder="Enter Project Name" />
          <S.ErrMessage component="div" name="name" />
          <S.FieldTitle>Page</S.FieldTitle>
          <S.Field name="page" type="string" placeholder="Enter Page" />
          <S.ErrMessage component="div" name="page" />
          {role !== "user" && (
            <>
              <S.FieldTitle>CRI</S.FieldTitle>
              <S.Field name="CRI" type="string" placeholder="Enter CRI" />
            </>
          )}
          <S.SubmitButton type="submit">SUBMIT</S.SubmitButton>
        </S.Form>
      </Formik>
    </Modal>
  );
};

export default EditUserModal;
