import React from 'react'

import RegistrationForm from './components/RegistrationForm';

import * as S from './styled';

function RegistrationPage({ history }) {
    return (
        <S.Container>
            <S.Title>User Registration</S.Title>
            <RegistrationForm history={history}/>
        </S.Container>
    )
}

export default RegistrationPage
