import React from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";

import { actions as authActions } from "redux/auth";

import * as S from "./styled";

function RegistrationForm({ history }) {
  const dispatch = useDispatch();

  const handleValidate = values => {
    let errors = {};
    const { email, password } = values;

    if (!email) {
      errors.email = "Email is required";
    }

    if (!password) {
      errors.password = "Password is required";
    }

    return errors;
  };

  const handleSubmit = values => {
    dispatch(authActions.authenticationRequest({ values, history }));
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      validate={handleValidate}
      initialValues={{
        email: "",
        password: ""
      }}
    >
      <S.Form>
        <S.FieldTitle>Email</S.FieldTitle>
        <S.Field name="email" type="string" placeholder="Enter the Email" />
        <S.ErrMessage component="div" name="email" />
        <S.FieldTitle>Password</S.FieldTitle>
        <S.Field
          name="password"
          type="password"
          placeholder="At least 6 characters long"
        />
        <S.ErrMessage component="div" name="password" />
        <S.SubmitButton type="submit">SUBMIT</S.SubmitButton>
      </S.Form>
    </Formik>
  );
}

export default RegistrationForm;
